//--------LIBRARIES--------
import React from "react";
import { Modal, Button, Tabs } from "antd";

export default function TrademarksAlgosInfo({ visible, onClose }) {
  const { TabPane } = Tabs;
  return (
    <Modal
      title={<h2>Search Algorithms</h2>}
      open={visible}
      onOk={onClose}
      footer={
        <Button type="primary" onClick={onClose}>
          OK
        </Button>
      }
      maskClosable={true}
      onCancel={onClose}
      width="800px"
    >
      <Tabs defaultActiveKey="Flash">
        <TabPane tab="Flash" key="Flash">
          <div>
            <p>Combines following searches into one search:</p>
            <ul>
              <li>Fuzzy</li>
              <li>Wildcard</li>
              <li>Standard</li>
              <li>Contains</li>
              <li>Phonetic</li>
              <li>Boolean (for 2 or more words)</li>
              <li>Initial (for 2 or more words)</li>
            </ul>
            <br />
            <p>With following default values:</p>
            <ul>
              <li>
                Wildcard - <b>[[*]]</b> your mark <b>[[*]].</b>
              </li>
              <li>
                Fuzzy - Character Variation upto 40% of the characters in your
                mark.
              </li>
              <li>
                Boolean - your mark <b>[[&]]</b> your mark.
              </li>
              <li>Contains - Contains search on each word of your mark.</li>
            </ul>
            <p>
              For more thorough search, please supplement with individual
              searches.
            </p>
          </div>
        </TabPane>
        <TabPane tab="Wildcard" key="Wildcard">
          <div>
            <p>
              Perform Multi/Unlimited Character variation, Single Character
              Variation, Suffix, Prefix and Contains based searches.
            </p>
            <p>
              For Prefix, Suffix, Contains or multi/Unlimited Character
              variation choose "Unlimited character variation [[*]]" from above.
            </p>
            <p>
              <b style={{ borderBottom: "1.5px solid" }}>SEARCH EXAMPLE</b>
            </p>
            <ul style={{ listStyle: "none" }}>
              <li>
                <b>[[*]]</b> Legal →{" "}
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  Mike
                </span>
                Legal,{" "}
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  RSR
                </span>{" "}
                Legal and Legal
              </li>
              <li>
                Mike <b>[[*]]</b> → Mike
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  Legal
                </span>
                , Mike{" "}
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  Ross
                </span>{" "}
                and Mike
              </li>
              <li>
                <b>[[*]]</b> Mike <b>[[*]]</b> → Mike
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  Legal
                </span>
                ,{" "}
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  Xyz
                </span>
                Mike
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  123
                </span>{" "}
                and Mike
              </li>
            </ul>
            <br />
            <p>
              For Single Character variation search, choose "Single character
              variation [[?]]" from above.
            </p>
            <p>
              <b style={{ borderBottom: "1.5px solid" }}>SEARCH EXAMPLE</b>
            </p>
            <ul style={{ listStyle: "none" }}>
              <li>
                <b>[[?]]</b> rystal →{" "}
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  C
                </span>
                rystal,{" "}
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  T
                </span>
                rystal and{" "}
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  K
                </span>
                rystal
              </li>
            </ul>
          </div>
        </TabPane>
        <TabPane tab="Initials" key="Initials">
          <div>
            <p>
              Initial Search allows you to identify marks with same initials.
            </p>
            <p>
              <b style={{ borderBottom: "1.5px solid" }}>SEARCH EXAMPLE</b>
            </p>
            ‘Officers Choice’ → ‘Oliver Champagne’, ‘Omega Clove’
          </div>
        </TabPane>
        <TabPane tab="Fuzzy" key="Fuzzy">
          <div>
            <p>
              Fuzzy search allows you to identify marks with character
              variations to your mark. You can add fuzz depth from 2 to the
              lenght of your mark.
            </p>
            <p>
              <b style={{ borderBottom: "1.5px solid" }}>SEARCH EXAMPLE</b>
            </p>
            <ul style={{ listStyle: "none" }}>
              <li>
                MikeLegal <b>with fuzz depth</b> 2 → MikeLeg
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  [al]
                </span>
                , MikeLegal
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  12
                </span>
              </li>
              <li>
                MikeLegal <b>with fuzz depth</b> 3 → MikeLe
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  [gal]
                </span>
                , MikeLegal
                <span
                  style={{
                    color: "#FF5722",
                    fontWeight: "600",
                    fontFamily: "sans-serif",
                  }}
                >
                  xyz
                </span>
              </li>
            </ul>
          </div>
        </TabPane>
        <TabPane tab="Boolean" key="Boolean">
          <div>
            <p>
              Boolean Search allows you to use characters <b>AND</b>, <b>OR</b>{" "}
              and <b>NOT</b> to get customised set of results. <b>AND</b> allows
              you to get results having both the elements present in the search
              query. <b>OR</b> allows you to get results with either of the
              element present in the search query. <b>NOT</b> allows you to
              remove a particular mark from the result set to get customised
              results.
            </p>
            <p>
              <b style={{ borderBottom: "1.5px solid" }}>SEARCH EXAMPLE</b>
            </p>
            <ul style={{ listStyle: "none" }}>
              <li>
                Rock <b>[[&]]</b> Cafe → Rock Bottom Cafe, Cafe Rock Inn, Hard
                Rock Cafe and Cafe Rock
              </li>
              <li>
                Rock <b>[[|]]</b> Cafe → Rock Card, Cafe Roman and Rock Cafe
              </li>
              <li>
                Rock <b>[[!]]</b> Cafe <b>[[!]]</b> Hard → Rock Bottom Cafe,
                Cafe Rock Inn and Cafe Rock. (Here, marks which has Hard in it
                will not be shown)
              </li>
            </ul>
          </div>
        </TabPane>
        <TabPane tab="Prefix" key="Prefix">
          <div>
            <p>
              The Prefix Algorithm is designed to simplify trademark searches,
              particularly for word marks. By default, it analyses the first
              three letters (or "prefix") of a word, but users have the
              flexibility to modify the prefix depth to find more relevant
              matches. This approach is highly effective because many trademarks
              may share the same beginning yet differ in the remaining letters.
            </p>
            <p>
              As you search for longer words, you can increase or decrease the
              prefix length to refine the search, giving you more control over
              how broad or precise the results are. This flexibility allows
              users to customize their search, ensuring it fits their specific
              needs while maintaining efficiency.
            </p>
            <p>For example, </p>
            <p>
              If you're searching for trademarks beginning with "Tech" the
              algorithm will identify all trademarks with that prefix, such as
              "TechCorp" "TechWorld" or "Techify" helping you capture a broad
              range of relevant results.
            </p>
          </div>
        </TabPane>
        <TabPane tab="Suffix" key="Suffix">
          <div>
            <p>
              The Suffix Algorithm is designed to streamline trademark searches
              by focusing on the ending (or "suffix") of a word mark. By
              default, it analyses the last three letters, but users have the
              flexibility to adjust the suffix depth to identify more relevant
              matches. This approach is particularly useful because many
              trademarks may have different beginnings but share similar
              endings, making it easier to find related marks.
            </p>
            <p>
              As the length of the word you're searching for increases, you can
              modify the suffix length to fine-tune your search. This allows for
              greater precision when necessary, giving users control over the
              search scope while ensuring relevant results.
            </p>
            <p>For example, </p>
            <p>
              if you're searching for trademarks ending with "Corp" the
              algorithm will identify all trademarks that share this suffix,
              such as "TechCorp" "BioCorp" or "GreenCorp." This makes the search
              both comprehensive and efficient, allowing users to tailor their
              results based on specific suffix patterns.
            </p>
          </div>
        </TabPane>
      </Tabs>
    </Modal>
  );
}
