import React from "react";
import { Alert, Layout } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

const { Content } = Layout;
const MikeContainer = ({
  header,
  alert,
  content,
  modals,
  showHeader = true,
  customHeight,
}) => {
  return (
    <div
      style={{
        background: "white",
        height: customHeight ? customHeight : "100vh",
      }}
    >
      {header}
      {showHeader && <hr style={{ marginTop: "0px", marginBottom: "0px" }} />}
      {alert && (
        <Alert
          style={{ margin: "5px" }}
          message={
            <span>
              <InfoCircleOutlined
                style={{
                  fontSize: "20px",
                  verticalAlign: "0px",
                  color: "#4285f4",
                  marginRight: "10px",
                  backgroundColor: "#ffffff",
                  border: "2px solid #ffffff",
                  borderRadius: "50%",
                }}
              />
              {alert}
            </span>
          }
          type="info"
        />
      )}
      {content}
      {modals}
    </div>
  );
};

export default MikeContainer;
