//--------LIBRARIES--------
import React from "react";
import { EyeFilled, EyeOutlined, StarFilled } from "@ant-design/icons";
import { Checkbox, Image, Tooltip, Card } from "antd";

//--------Check value functions--------
import { checkNA } from "../../../functions/commonFunctions/checkValueFunctions";
import "../../../stylesheets/searchStyleSheets/search.css";

function GeneralMarkCard({
  eachMark,
  onSelectMark,
  checked,
  selectAllCards,
  changeStateValue_L1,
  status,
  proprietorClickable,
  proprietorFlat,
  onProprietorClick,
  tmProtect,
  customClassName,
}) {
  const textLabel = (label, text) => (
    <div style={{ margin: "4px 0px" }}>
      <div className="Threat-Mark-Card-Title">{label} :</div>
      <div className="Threat-Mark-Card-Detail">{text}</div>
    </div>
  );

  const content = (
    <Card
      className={customClassName && customClassName}
      style={{ minWidth: 200, padding: "8px", border: "none" }}
      size="small"
      cover={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              margin: "2px",
            }}
          >
            <Checkbox
              checked={checked}
              onChange={(event) => onSelectMark(eachMark, event.target.checked)}
              disabled={selectAllCards}
              className={`${customClassName}check checkbox-content-list`}
            />
            <p
              className={tmProtect ? "" : "Application-Number"}
              style={{ margin: "0px 8px" }}
              onClick={() => {
                tmProtect
                  ? changeStateValue_L1()
                  : changeStateValue_L1(
                      "drawersVisibility",
                      "journalPage",
                      eachMark.application_number,
                    );
              }}
            >
              <span className={`${customClassName}app`}>
                {eachMark.application_number}
              </span>
            </p>
            {eachMark?.created_custom_report && (
              <Tooltip title="Custom Report Created">
                <EyeFilled
                  style={{
                    color: "#1890FF",
                    fontSize: "18px",
                    margin: "0px 4px",
                  }}
                />
              </Tooltip>
            )}
            {eachMark?.is_shortlist && (
              <Tooltip title="Shortlisted">
                <StarFilled
                  style={{
                    margin: "0px 4px",
                    color: "#FFA701",
                    fontSize: "18px",
                  }}
                />
              </Tooltip>
            )}
          </div>
          {eachMark?.associated_image ? (
            <div style={{ padding: "4px" }}>
              <Image
                src={eachMark?.associated_image}
                style={{
                  height: "100px",
                  maxWidth: "200px",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                height: "100px",
                fontSize: "20px",
                textAlign: "center",
                wordBreak: "break-word",
                overflow: "auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxWidth: "220px",
              }}
            >
              {checkNA(eachMark?.applied_for)}
            </div>
          )}
        </div>
      }
    >
      <Card.Meta
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "300px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div>
              {textLabel("Applied For", eachMark?.applied_for)}
              {status && textLabel("Status", status)}

              {textLabel(
                "Classes",
                eachMark?.associated_class.map(
                  (eachClass, index, allClasses) =>
                    allClasses.length - 1 === index
                      ? `${eachClass}`
                      : `${eachClass}, `,
                ),
              )}
              {textLabel(
                "Doa | Dou",
                `${eachMark?.application_date} | ${eachMark.date_of_usage ? eachMark.date_of_usage : "Proposed"}`,
              )}
              {proprietorFlat &&
                textLabel(
                  "Proprietors",
                  <>
                    <span>{eachMark.proprietor_name}</span>
                    <br />
                  </>,
                )}
              {!proprietorFlat &&
                textLabel(
                  "Proprietors",
                  eachMark.proprietor.map((eachProprietor) => (
                    <React.Fragment
                      key={`${eachMark.application_number}-${eachProprietor.id}`}
                    >
                      {proprietorClickable ? (
                        <span
                          className="Application-Number"
                          onClick={() => onProprietorClick(eachProprietor.id)}
                        >
                          {eachProprietor.name}
                        </span>
                      ) : (
                        <span>{eachProprietor.name}</span>
                      )}
                      <br />
                    </React.Fragment>
                  )),
                )}
            </div>
          </div>
        }
        // description={
        //   <div>
        //     <hr />
        //     <p onClick={() => handleEmail()}>
        //       <Tooltip title={"Send email"}>
        //         <i
        //           id="threat-card-sendmail"
        //           className="fa fa-paper-plane"
        //           style={{
        //             padding: "0px 5px",
        //             color: "#1890ff",
        //             fontSize: 20,
        //             cursor: "pointer",
        //           }}
        //           aria-hidden="true"
        //         ></i>
        //       </Tooltip>
        //     </p>
        //   </div>
        // }
      />
    </Card>
  );

  return (
    <div
      style={{
        margin: "8px",
        borderRadius: "8px",
        boxShadow: "0px 0px 5px #c9c9c9",
      }}
    >
      {content}
    </div>
  );
}

export default GeneralMarkCard;
