import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import _ from "lodash";

import { CarryOutTwoTone, MessageTwoTone } from "@ant-design/icons";

import {
  Row,
  Col,
  Table,
  Card,
  Button,
  message,
  Typography,
  notification,
  Switch,
  Tooltip,
} from "antd";

//--------COMMON CONSTANTS--------
import { LOADING } from "../../../../constants/commonConstants/generalConstants";

//--------ACTIONS--------
import { getPendingWorkingOfPatent } from "../../../../redux/actions/managerActions/patentDashboard/actions";

import { history } from "../../../../history";

import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import { changeStateValue_L0 } from "../../../../functions/commonFunctions/generalFunctions";
import { Patent_DefaultSelectedFilters } from "../../../../constants/managerConstants/patentConstants";

import RenewalFiledModal from "../RenewalFiled";
import CreateTask from "../../to-do-task/CreateTask";
import DownloadReportModal from "../../report/DownloadReportModal";
import { logOut } from "../../../../redux/actions/commonActions/actions";

import ManagerNotes from "../../subComponents/Modal/managerNotes";
import { P } from "../../../../constants/managerConstants/managerConstants";
import {
  POST_P_NOTES,
  PATCH_P_NOTES,
} from "../../../../redux/actions/managerActions/commonAction/types";
import SendEmail from "../../../commonComponents/Modals/sendMail";
import SendEmailNew from "../../../commonComponents/Modals/SendEmailNew";

const { Text } = Typography;

class PendingWorkingOfPatent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      renewalFiledVisible: false,
      addTaskVisible: false,
      pageNumber: this.props.patentDashboardDataPageNumber,
      add_patent_id: [],
      selectedRowKeys: [],
      management_patent_id: "",
      applicant_id: "",
      inventor_id: "",
      selectAll: false,
      changeSwitch: false,
      managerNotes: false,
      modalsVisibility: {
        sendEmail: false,
        mailContent: "",
        report_tag: {},
      },
    };
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.patentDashboardData === null) {
        this.props.getPendingWorkingOfPatent({
          pseudo: false,
        });
      }
    } else {
      this.props.logOut();
    }
  }

  handlPatentProfile(data) {
    if (data) {
      sessionStorage.setItem("management_patent_id", data.management_patent_id);
      sessionStorage.setItem(
        "patent_application_number",
        data.application_number,
      );
      window.open(
        "/manager/patent/patent-profile/" + data.management_patent_id,
        "_blank",
      );
      this.setState({
        management_patent_id: data.management_patent_id,
      });
    }
  }

  handleApplicantProfile(data) {
    if (data) {
      sessionStorage.setItem("applicant_id", data.id);
      window.open("/manager/patent/applicant-profile/" + data.id, "_blank");
      this.setState({
        applicant_id: data.id,
      });
    }
  }

  downloadReport() {
    if (this.state.add_patent_id.length > 0) {
      this.setState({
        visible: true,
      });
    } else {
      message.error("no mark selected");
    }
  }

  renewalFiled(data) {
    this.setState({
      renewalFiledVisible: true,
      renewal: false,
      application_number: data.application_number,
      title_of_invention: data.title_of_invention,
      pseudo: this.state.changeSwitch,
    });
  }

  handleNextpage = (pageNumber) => {
    this.props.getPendingWorkingOfPatent({
      pseudo: this.state.changeSwitch,
      pageNumber: pageNumber,
    });
    this.setState({
      pageNumber: pageNumber,
    });
  };

  changeSwitch() {
    this.setState({
      changeSwitch: !this.state.changeSwitch,
      add_patent_id: [],
      selectAll: false,
      selectedRowKeys: [],
      pageNumber: 1,
    });
  }

  handleManagerNotes(data) {
    this.setState({
      managerNotes: true,
      application_number: data.application_number,
    });
  }

  handleEmail(modalsVisibility, modal, value, data) {
    let mailContent = "<p><strong>Patent Manager</strong></p>";
    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        mailContent: mailContent,
        product: "MANAGER",
        subscription: [this.state.changeSwitch ? "PSEUDO_PATENT" : "PATENT"],
        report_tag: {
          proprietor_name: checkValue(data.proprietor)
            ? data.proprietor.map((prop) => prop.name).join(", ")
            : "",
          status: checkValue(data.application_status)
            ? data.application_status
            : "",
          report_name: "",
        },
        objectType: this.state.changeSwitch ? "pseudo_patent" : "patent",
        applicationNumber: data.application_number,
        sourceId: data.management_patent_id,
      },
    });
  }

  render() {
    const { patentDashboardData, patentDashboardDataStatus } = this.props;

    const totalPatentColumns = [
      {
        title: "PATENT",
        dataIndex: "application_number",
        className: "reply-column-1",
        render: (text, record) => {
          return (
            <div>
              <p
                className="application-text"
                onClick={() => this.handlPatentProfile(record)}
              >
                <span>
                  {checkValue(record.application_number)
                    ? record.application_number
                    : "N.A."}{" "}
                  -{" "}
                </span>
                <span className="text-camelCase">
                  {checkValue(record.title_of_invention)
                    ? record.title_of_invention.toLowerCase()
                    : "N.A."}
                </span>
              </p>
            </div>
          );
        },
      },

      {
        title: "APPLICANT",
        dataIndex: "applicants",
        className: "reply-column-2",
        render: (applicant) => {
          return (
            <div>
              {applicant.length === 0 && <p>NA</p>}
              {applicant.map((applicantData, applicantKey) => (
                <p key={applicantKey} className="text-camelCase">
                  {checkValue(applicantData)
                    ? applicantData.toLowerCase()
                    : "N.A."}
                </p>
              ))}
            </div>
          );
        },
      },

      {
        title: "APPLICATION FILING DATE",
        dataIndex: "application_filing_date",
        className: "patent-column",
        render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
      },

      {
        title: "GRANT DATE",
        dataIndex: "date_of_grant",
        className: "patent-column",
        render: (text) => <p>{checkValue(text) ? text : "N.A."}</p>,
      },

      {
        title: "DUE DATE",
        dataIndex: "days_left",
        className: "patent-column",
        render: (text) => <p>{checkValue(text) ? `${text}` : "N.A."}</p>,
      },

      {
        title: "ACTION",
        className: "patent-column",
        render: (action) => {
          return (
            <div>
              {/* <p className="application-text alignC" onClick={() => this.renewalFiled(action)}>Renewal Filed</p> */}
              <Tooltip placement="top" title={"Create To-do Task"}>
                <p className="alignC">
                  <CarryOutTwoTone
                    onClick={() =>
                      this.changeStateValue_L0("addTaskVisible", true)
                    }
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Notes"}>
                <p className="alignC">
                  <MessageTwoTone
                    onClick={() => this.handleManagerNotes(action)}
                    style={{ cursor: "pointer", fontSize: "30px" }}
                  />
                </p>
              </Tooltip>
              <Tooltip placement="top" title={"Send email"}>
                <p
                  className="alignC"
                  onClick={() =>
                    this.handleEmail(
                      "modalsVisibility",
                      "sendEmail",
                      true,
                      action,
                    )
                  }
                >
                  <i
                    className="fa fa-paper-plane"
                    style={{
                      padding: "0px 5px",
                      color: "#1890ff",
                      fontSize: 20,
                    }}
                    aria-hidden="true"
                  ></i>
                </p>
              </Tooltip>
            </div>
          );
        },
      },
    ];

    const totalPatentRowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        let select_patent_id = [];
        select_patent_id = selectedRows.map((mark_id) => mark_id.id);
        this.setState({
          selectedRows: selectedRows,
          add_patent_id: select_patent_id,
          selectedRowKeys: selectedRowKeys,
        });
      },

      onSelectAll: (selected, selectedRows) => {
        this.setState({
          selectAll: selected,
          selectedRows: selectedRows,
        });
        if (selected) {
          notification.open({
            message: "Deselect All Mark",
            description:
              "Click on the arrow near the select all checkbox to deselect all marks",
          });
        }
      },

      onSelect: (record, selected, selectedRows) => {
        this.setState({
          selectRow: selected,
          selectAll: selectedRows.length === 100 ? true : false,
        });
      },

      hideDefaultSelections: true,
      selections: this.state.selectAll
        ? [
            {
              key: "all-data",
              text: "Deselect All Data",
              onSelect: (changeableRowKeys) => {
                this.setState({
                  selectedRowKeys: changeableRowKeys,
                  selectAll: !this.state.selectAll,
                  add_patent_id: [],
                });
              },
            },
          ]
        : false,

      selectedRowKeys: this.state.selectedRowKeys,

      getCheckboxProps: (record) => ({
        disabled: this.state.selectAll,
      }),
    };

    return (
      <div>
        {/* Change-Switch */}
        <Row
          className={
            checkValue(this.props.userSubscriptions)
              ? this.props.userSubscriptions.manager.includes("PSEUDO_PATENT")
                ? ""
                : "display_none"
              : "display_none"
          }
        >
          <Col span={24} className="alignR m-top-10">
            <Text strong>IP INDIA</Text>
            <Switch
              className="change-manager-type"
              onChange={() => this.changeSwitch()}
              checked={this.state.changeSwitch}
            />
            <Text strong>CUSTOM</Text>
          </Col>
        </Row>
        {/* Change-Switch */}

        {/* upcomming renewals */}
        <Row>
          <Col span={24} className="m-top-10">
            <Card
              title="PENDING WORKING OF PATENT ( FORM-27 )"
              extra={
                <div>
                  <Text style={{ padding: "0px 5px" }}>
                    {this.state.changeSwitch
                      ? this.state.selectAll
                        ? patentDashboardData &&
                          patentDashboardData.pseudo.count
                        : _.uniq(this.state.add_patent_id).length
                      : this.state.selectAll
                        ? patentDashboardData &&
                          patentDashboardData.ip_india.count
                        : _.uniq(this.state.add_patent_id).length}{" "}
                    Patent(s)
                  </Text>
                  <Button type="primary" onClick={() => this.downloadReport()}>
                    Report
                  </Button>
                </div>
              }
            >
              <Table
                rowKey={(record) => record.id}
                bordered
                rowSelection={totalPatentRowSelection}
                columns={totalPatentColumns}
                dataSource={
                  this.state.changeSwitch
                    ? patentDashboardData &&
                      patentDashboardData.pseudo &&
                      patentDashboardData.pseudo.patent
                    : patentDashboardData &&
                      patentDashboardData.ip_india &&
                      patentDashboardData.ip_india.patent
                }
                pagination={{
                  pageSize: 100,
                  showQuickJumper: true,
                  showSizeChanger: false,
                  defaultCurrent: 1,
                  current: this.state.pageNumber,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} `,
                  total: this.state.changeSwitch
                    ? patentDashboardData &&
                      patentDashboardData.pseudo &&
                      patentDashboardData.pseudo.count
                    : patentDashboardData &&
                      patentDashboardData.ip_india &&
                      patentDashboardData.ip_india.count,
                  onChange: this.handleNextpage,
                }}
                scroll={{ y: "calc(70vh - 200px)" }}
                loading={patentDashboardDataStatus === LOADING ? true : false}
              />
            </Card>
          </Col>
        </Row>
        {/* upcomming renewals */}
        {this.state.visible && (
          <DownloadReportModal
            visible={this.state.visible}
            onCloseModal={() => this.setState({ visible: false })}
            type={"patent"}
            // upcoming_flag={this.state.selectAll}
            add_patent_id={this.state.add_patent_id}
            filters={Patent_DefaultSelectedFilters}
            working_of_patent={this.state.selectAll}
          />
        )}

        <RenewalFiledModal
          visible={this.state.renewalFiledVisible}
          onCloseModal={() =>
            this.changeStateValue_L0("renewalFiledVisible", false)
          }
          renewal={this.state.renewal}
          application_number={this.state.application_number}
          title_of_invention={this.state.title_of_invention}
          pseudo={this.state.pseudo}
        />

        <CreateTask
          visible={this.state.addTaskVisible}
          onCloseModal={() => this.changeStateValue_L0("addTaskVisible", false)}
          type={"patent"}
        />

        <ManagerNotes
          visible={this.state.managerNotes}
          onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
          actionType={[POST_P_NOTES, PATCH_P_NOTES]}
          email={
            checkValue(this.props.userDetails)
              ? this.props.userDetails.email
              : ""
          }
          note_for={P}
          application_number={this.state.application_number}
          type={"ADD"}
        />

        <SendEmailNew
          visible={this.state.modalsVisibility.sendEmail}
          onCloseModal={() =>
            this.handleEmail("modalsVisibility", "sendEmail", false, {})
          }
          sendFrom={
            this.props.userDetails && this.props.userDetails.communication_email
          }
          sendCC={this.props.userTeam && this.props.userTeam.manager}
          mailContent={this.state.modalsVisibility.mailContent}
          product={this.state.modalsVisibility.product}
          subscription={this.state.modalsVisibility.subscription}
          report_tag={this.state.modalsVisibility.report_tag}
          objectType={this.state.modalsVisibility.objectType}
          applicationNumber={this.state.modalsVisibility.applicationNumber}
          source={`PATENT`}
          sourceId={this.state.modalsVisibility.sourceId}
          managerEmail={true}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getPendingWorkingOfPatent,
  logOut,
};

function mapStateToProps(state) {
  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,

    patentDashboardData:
      state.managerState.patentDashboardState.pendingWorkingOfPatent,
    patentDashboardDataStatus:
      state.managerState.patentDashboardState.pendingWorkingOfPatentStatus,
    patentDashboardDataPageNumber:
      state.managerState.patentDashboardState.pendingWorkingOfPatentPageNumber,
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PendingWorkingOfPatent);
