const initialState = {
  inventorsConfig: {
    key: "inventors",
    menu_label: "Inventors",
    data: [],
    AndOrButton: {
      show: true,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    excludeValues: [],
    searchValue: "",
  },
  applicationTypeConfig: {
    key: "application_type",
    menu_label: "Application Type",
    data: [],
    AndOrButton: {
      show: true,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    excludeValues: [],
    searchValue: "",
  },
  applicantConfig: {
    key: "applicant",
    menu_label: "Applicant",
    data: [],
    AndOrButton: {
      show: true,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    excludeValues: [],
    searchValue: "",
  },
  dateOfPriorityConfig: {
    key: "date_of_priority",
    value: {},
  },
  filingDateConfig: {
    key: "filing_date",
    value: {},
  },
  dueDateConfig: {
    key: "due_date",
    value: {},
  },
  reduxSearchValue: "",
  isFilterApplied: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
