import { Button, Card, Col, Empty, Row } from "antd";
import React, { useEffect, useState } from "react";
import DeadlineSection from "./DeadlineSection";
import AddEditPatent from "../Manage/formComponents/AddEditPatent";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getSinglePublishedPatentData,
  updateUnPublishedPatentDeadlines,
} from "../../../../../redux/actions/managerActions/unPublishedPatentDashboard/managePatent";
import {
  applicationOfficeList,
  applicationStatusList,
  applicationTypeList,
} from "../Manage/common/constant";
import dayjs from "dayjs";

const individualColumnStyle = {
  border: "1px solid #dee2e6",
  paddingTop: "5px",
  paddingRight: "5px",
  paddingBottom: "5px",
  paddingLeft: "5px",
  display: "flex",
  alignItems: "center",
};

const labelStyling = {
  fontWeight: "500",
};

const UnPublishPatentDetail = () => {
  const { patentId } = useParams();
  const dispatch = useDispatch();
  const [openEditForm, setOpenEditForm] = useState(false);

  const { data, loading } = useSelector(
    (state) => state.managerState.unPublishedPatent.manage.singleData,
  );

  const getPatentDetail = () => {
    dispatch(getSinglePublishedPatentData(patentId));
  };

  useEffect(() => {
    patentId && getPatentDetail();
  }, [patentId]);

  // Function to handle arrays and convert them to comma-separated strings
  const formatArray = (arr) => {
    if (!arr || arr.length === 0) {
      return "N/A"; // Return "N/A" if the array is null, undefined, or empty
    }

    if (Array.isArray(arr)) {
      return arr.map((item) => item.name).join(", ");
    }

    return arr; // Return as is if it's not an array (for fallback)
  };

  const applicationData = [
    {
      key: "applicant_name",
      label: "Applicant Name",
      value: formatArray(data?.applicants),
    },
    {
      key: "inventor_name",
      label: "Inventor Name",
      value: formatArray(data?.inventors),
    },
    {
      key: "application_type",
      label: "Application Type",
      value: data?.application_type
        ? applicationTypeList?.find(
            (item) => item.value === data.application_type,
          )?.label || "N/A"
        : "N/A",
    },
    {
      key: "application_number",
      label: "Application Number",
      value: data?.application_number || "N/A",
    },
    {
      key: "filing_date",
      label: "Application Filing Date",
      value: data?.filing_date || "N/A",
    },
    {
      key: "title",
      label: "Title",
      value: data?.title || "N/A",
    },
    {
      key: "application_office",
      label: "Application Office",
      value: data?.application_office
        ? applicationOfficeList?.find(
            (item) => item.value === data.application_office,
          )?.label || "N/A"
        : "N/A",
    },
    {
      key: "application_status",
      label: "Application Status",
      value: data?.application_status
        ? applicationStatusList?.find(
            (item) => item.value === data.application_status,
          )?.label || "N/A"
        : "N/A",
    },
    {
      key: "priority_country",
      label: "Priority Country",
      value: data?.priority_country || "N/A",
    },
    {
      key: "priority_number",
      label: "Priority Number",
      value: data?.priority_number || "N/A",
    },
    {
      key: "date_of_priority",
      label: "Priority Date",
      value: data?.date_of_priority || "N/A",
    },
    {
      key: "pct_application_number",
      label: "PCT Application Number",
      value: data?.pct_international_application_number || "N/A",
    },
    {
      key: "date_of_filing_pct_application",
      label: "PCT Application Filing Date",
      value: data?.date_of_filing_pct_application || "N/A",
    },
    {
      key: "parent_application_number",
      label: "Parent Application Number",
      value: data?.parent_application_number || "N/A",
    },
    {
      key: "post_dated_by",
      label: "Post Dated by",
      value: data?.post_dated_by || "N/A",
    },
  ];

  const oldData = [
    {
      key: "old_filing_date",
      label: "Old filing date",
      value: data?.old_filing_date || "N/A",
    },
  ];

  // Reusable component for rendering the rows
  const DataRow = ({ label, value }) => (
    <Row gutter={[16, 16]}>
      <Col xs={24} sm={8} style={{ ...individualColumnStyle, ...labelStyling }}>
        {label}
      </Col>
      <Col xs={24} sm={16} style={individualColumnStyle}>
        {value}
      </Col>
    </Row>
  );

  const handleDateChange = (values, deadlineId) => {
    const body = {
      complied_date: values ? dayjs(values).format("DD-MM-YYYY") : null,
      pk: deadlineId,
      update_deadline: true,
    };
    dispatch(
      updateUnPublishedPatentDeadlines(body, {
        onSuccess: () => {
          dispatch(getSinglePublishedPatentData(patentId));
        },
      }),
    );
  };

  const handleEditClick = () => {
    setOpenEditForm(true);
  };

  const propsForEditPatent = {
    open: openEditForm,
    setOpen: setOpenEditForm,
    type: "EDIT",
    selectedDataForEdit: data,
    getUnPublishedPatentData: getPatentDetail,
  };

  return (
    <Row gutter={16} className="m-10">
      {openEditForm && <AddEditPatent {...propsForEditPatent} />}
      <Col span={24}>
        {/* Card Section for Patent Information Starts  */}
        <Card
          title="Patent (Unpublished) Information"
          extra={
            <Button
              type="primary"
              disabled={loading || !Object.keys(data)?.length}
              onClick={() => handleEditClick()}
            >
              Edit
            </Button>
          }
          headStyle={{ background: "#d9dcdf" }}
          loading={loading}
        >
          {data && Object.keys(data)?.length ? (
            applicationData.map(({ key, label, value }) => (
              <DataRow key={key} label={label} value={value} />
            ))
          ) : (
            <Empty />
          )}
        </Card>
        {/* Card Section for Patent Information Ends  */}

        {/* Card Section for Deadline Starts */}
        <DeadlineSection
          deadlineData={data?.deadlines ?? []}
          handleDateChange={handleDateChange}
          loading={loading}
        />
        {/* Card Section for Deadline Starts */}

        {/* Card Section for Old Data Starts  */}
        {/* <Card
          title="Old Patent Information"
          headStyle={{ background: "#d9dcdf" }}
          loading={loading}
        >
          {data && Object.keys(data)?.length ? (
            oldData.map(({ key, label, value }) => (
              <DataRow key={key} label={label} value={value} />
            ))
          ) : (
            <Empty />
          )}
        </Card> */}
        {/* Card Section for Old Data Ends  */}
      </Col>
    </Row>
  );
};

export default UnPublishPatentDetail;
