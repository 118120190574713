import { FilterFilled } from "@ant-design/icons";
import { Button, Col, Input, Row } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectWithExcludeFilterContainer from "./filtersContainer/SelectWithExcludeFilterContainer";
import SingleDateFilterContainer from "./filtersContainer/SingleDateFilterContainer";
import {
  changeUnPublishedPatentFilterApplied,
  changeUnPublishedPatentGlobalSearchValues,
  changeUnPublishedPatentResetAllFilter,
} from "../../../../../redux/actions/managerActions/unPublishedPatentDashboard/managePatent";

const PrimaryFilters = (props) => {
  const { tableDataLoading } = props;
  const dispatch = useDispatch();

  const filterSelected = props?.isFilterSelected?.();

  const {
    inventorsConfig,
    application_officesConfig,
    date_of_priorityConfig,
    globalSearchValue,
    isFilterApplied,
  } = useSelector(
    (state) => state.managerState.unPublishedPatent.manageFilters,
  );

  const handleGlobalSearchValue = (event) => {
    dispatch(changeUnPublishedPatentGlobalSearchValues(event.target.value));
  };

  const handleApplyFilters = () => {
    props?.getUnPublishedPatentData?.(false, true);
    dispatch(changeUnPublishedPatentFilterApplied(true));
  };

  const handleResetFilters = () => {
    dispatch(changeUnPublishedPatentResetAllFilter());
    props?.getUnPublishedPatentData?.(true);
  };

  const firstRow = (
    <Row style={{ width: "100%" }}>
      <Col span={24} style={{ display: "flex", gap: 13 }}>
        <Input
          placeholder={
            "Search Application No., Title, Priority Number, PCT Application No."
          }
          onChange={(event) => handleGlobalSearchValue(event)}
          value={globalSearchValue}
          allowClear
          className="search-value-input-field"
          style={{
            width: 450,
            background: "#F5F6F8",
            borderRadius: "0px",
          }}
        />
        <Button
          type="primary"
          icon={<FilterFilled />}
          disabled={!filterSelected || tableDataLoading}
          onClick={handleApplyFilters}
          loading={tableDataLoading}
        >
          Apply
        </Button>
        <Button
          type="text"
          danger
          onClick={() => handleResetFilters()}
          disabled={!isFilterApplied}
        >
          Reset All
        </Button>
      </Col>
    </Row>
  );

  const secondRow = (
    <Row style={{ width: "100%", marginTop: "15px" }}>
      <Col span={24} style={{ display: "flex", gap: 10 }}>
        <SelectWithExcludeFilterContainer
          filteredData={inventorsConfig}
          key={inventorsConfig.key}
          loading={inventorsConfig.loading}
        />
        <SelectWithExcludeFilterContainer
          filteredData={application_officesConfig}
          key={application_officesConfig.key}
          loading={application_officesConfig.loading}
        />
        <SingleDateFilterContainer
          menu_label={"Date of Priority"}
          subtitle1={"Date of Priority"}
          key={"manager_date_of_priority_filter"}
          filter_key_1="date_of_priority"
          value1={date_of_priorityConfig.value}
        />
      </Col>
    </Row>
  );

  return (
    <>
      <header className="General-Header">
        <Row style={{ padding: "10px 0px" }}>
          {firstRow}
          {secondRow}
        </Row>
      </header>
    </>
  );
};

export default PrimaryFilters;
