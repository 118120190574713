import React, { useEffect, useState, useRef } from "react";
import { Dropdown, Button, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import DateRangePicker from "../../../../functions/commonFunctions/dateRangePicker";
import dayjs from "dayjs";

const CommonSingleDateFilter = ({
  menu_label = "",
  subtitle1 = "",
  value1 = { from: "", to: "", dateString: "" },
  tableView = false,
  filter_key_1 = "",
  handleDateChange,
}) => {
  const initialRange1 = useRef([
    value1.from ? dayjs(value1.from) : null,
    value1.to ? dayjs(value1.to) : null,
  ]);
  const [range1, setRange1] = useState(initialRange1.current);
  const [openFilter, setOpenFilter] = useState(false);

  useEffect(() => {
    setRange1([
      value1?.from ? dayjs(value1.from) : "",
      value1?.to ? dayjs(value1.to) : "",
    ]);
  }, [value1]);

  const handleDateStringUpdate =
    (filterKey, value, setRange) => (startValue, endValue, dateString) => {
      const updatedValue = {
        ...value,
        from: startValue,
        to: endValue,
        dateString: startValue || endValue ? dateString : null,
      };
      setRange([dayjs(startValue), dayjs(endValue)]);
      handleDateChange(filterKey, updatedValue);
    };

  const dateBox = () => (
    <>
      <div id="date-empty-div-id" style={{ padding: "10px" }}>
        <span style={{ fontWeight: "600" }}>{subtitle1}</span>
        <DateRangePicker
          startValue={value1.from}
          endValue={value1.to}
          setDate={(val) => setRange1(val)}
          range={range1}
          handleDateString={handleDateStringUpdate(
            filter_key_1,
            value1,
            setRange1,
          )}
        />
      </div>
    </>
  );

  return tableView ? (
    dateBox()
  ) : (
    <Dropdown
      dropdownRender={dateBox}
      overlayClassName="Filters-Menu"
      open={openFilter}
      onOpenChange={setOpenFilter}
    >
      <Button
        className={`${
          value1.dateString ? "Filters-Button-Selected" : "Filters-Button-Empty"
        }`}
        style={{ background: "inherit" }}
      >
        <Space>
          {menu_label}
          <DownOutlined style={{ marginTop: "10px" }} />
        </Space>
      </Button>
    </Dropdown>
  );
};

export default CommonSingleDateFilter;
