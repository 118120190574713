import React from "react";
import { useDispatch } from "react-redux";
import CommonSelectWithExcludeFilter from "../filters/CommonSelectWithExcludeFilter";
import {
  changeSearchActiveInActiveStatusFilter,
  changeSearchFilterExcludedValues,
  changeSearchFilterInputValue,
  changeSearchFilterSelectedValues,
} from "../../../redux/actions/searchActions/searchFiltersActions";
import { Checkbox } from "antd";
import { debounce } from "lodash";

const SelectContainer = (props) => {
  const { filteredData, key, loading, handleGetSingleFilter } = props;
  const dispatch = useDispatch();

  const handleChange = (newValue, newOption) => {
    const tempObject = newOption.map((item) => ({
      label: item.label,
      value: item.value,
    }));
    const tempValues = newOption.map((item) => item.value);
    dispatch(
      changeSearchFilterSelectedValues(
        filteredData.key,
        tempObject,
        tempValues,
      ),
    );
  };

  const handleSearchDebounced = React.useCallback(
    debounce(async (newValue) => {
      let body = {
        required_filter: filteredData.key.split("_")[1],
        page: 1,
        search_term: newValue,
      };
      handleGetSingleFilter?.(body);
    }, 500),
    [],
  );

  const handleSearch = (newValue) => {
    if (filteredData.key === "SEARCH_PROPRIETOR") {
      dispatch(changeSearchFilterInputValue(filteredData.key, newValue));
      handleSearchDebounced(newValue);
    } else dispatch(changeSearchFilterInputValue(filteredData.key, newValue));
  };

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (filteredData.data.length === filteredData.metadata.count) {
      return;
    }
    if (
      scrollTop + clientHeight >= scrollHeight - 20 &&
      filteredData.data.length < filteredData.metadata.count &&
      !filteredData.loading
    ) {
      // handle pagination for individual filters here  ------------------------------
      let body = {
        required_filter: filteredData.key.split("_")[1],
        page: filteredData.metadata.page + 1,
        search_term: filteredData.searchValue ? filteredData.searchValue : "",
      };
      handleGetSingleFilter?.(body);
    }
  };

  const handleExcludeButton = (event, item) => {
    event.stopPropagation();

    // Combine all data (both selected and excluded values)
    let allData = [...filteredData.data, ...filteredData?.excludeValues] || [];

    // Check if the item is already excluded
    const isExcluded = filteredData.excludeValues.some(
      (excludedItem) => excludedItem.value === item.value,
    );

    let updatedExcludeValues;

    if (isExcluded) {
      // If the item is already excluded, remove it from excludeValues
      updatedExcludeValues = filteredData.excludeValues.filter(
        (excludedItem) => excludedItem.value !== item.value,
      );
    } else {
      // If the item is not excluded, add it to excludeValues
      updatedExcludeValues = [
        ...filteredData.excludeValues,
        allData.find((dataItem) => dataItem.value === item.value),
      ];
    }

    // Ensure the updated excludeValues array is unique and doesn't duplicate any items
    const uniqueExcludeValues = updatedExcludeValues.reduce((unique, obj) => {
      return unique.some((item) => String(item.value) === String(obj.value))
        ? unique
        : [...unique, obj];
    }, []);
    const tempValues = uniqueExcludeValues.map((item) => item.value);

    // Dispatch the action to update the excluded values in the global state
    dispatch(
      changeSearchFilterExcludedValues(
        filteredData.key,
        uniqueExcludeValues,
        tempValues,
      ),
    );
  };

  const extraChildrenForStatusFilter = () => {
    return (
      <div>
        <div style={{ textAlign: "left", padding: "5px" }}>
          <Checkbox
            checked={filteredData?.activeStatus ?? false}
            onChange={(event) =>
              dispatch(
                changeSearchActiveInActiveStatusFilter(
                  filteredData.key,
                  "activeStatus",
                  event.target.checked,
                ),
              )
            }
          >
            Active
          </Checkbox>
        </div>
        <div style={{ textAlign: "left", padding: "5px" }}>
          <Checkbox
            checked={filteredData?.inActiveStatus ?? false}
            onChange={(event) =>
              dispatch(
                changeSearchActiveInActiveStatusFilter(
                  filteredData.key,
                  "inActiveStatus",
                  event.target.checked,
                ),
              )
            }
          >
            In-Active
          </Checkbox>
        </div>
      </div>
    );
  };

  const showExtraChildren = () => {
    switch (filteredData?.key) {
      case "SEARCH_STATUS":
        return extraChildrenForStatusFilter();

      default:
        return null;
    }
  };

  return (
    <CommonSelectWithExcludeFilter
      {...props}
      filteredData={filteredData}
      loading={loading}
      handleChange={handleChange}
      handleSearch={handleSearch}
      handleScroll={handleScroll}
      value={filteredData.selectedValues}
      exclude={filteredData.excludeValues}
      tableView={false}
      rename=""
      extraHighlight={
        filteredData?.activeStatus || filteredData?.inActiveStatus
      }
      filterId={key}
      handleExcludeButton={handleExcludeButton}
      extraChildren={showExtraChildren()}
    />
  );
};

export default SelectContainer;
