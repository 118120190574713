//--------LIBRARIES--------
import React, { useEffect } from "react";
import _ from "lodash";
import { Row, Col, Card, Spin } from "antd";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";

import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  Bar,
  Cell,
  XAxis,
  YAxis,
} from "recharts";
import { scaleOrdinal } from "d3-scale";
import { schemeCategory10 } from "d3-scale-chromatic";
import {
  renderBarCustomTooltip,
  renderPieCustomTooltip,
  renderPieCustomizedLabel,
  resetLabelPositions,
} from "../../../functions/commonFunctions/reChartsFunctions";

const colors = scaleOrdinal([
  ...schemeCategory10,
  ...schemeCategory10,
  ...schemeCategory10,
]).range();

function NewResultsAnalysis({
  analysisStatus,
  statusesDistribution,
  typesDistribution,
  classesDistribution,
  statesDistribution,
  proprietorsDistribution,
  handleGetAnalysis,
  statusLoading,
  typesLoading,
  classLoading,
  statesLoading,
  proprietorsLoading,
}) {
  useEffect(() => {
    handleGetAnalysis?.();
  }, []);

  let StatusSeries = [];
  let TypeSeries = [];
  let ClassSeries = [];
  let StateSeries = [];
  let ProprietorSeries = [];

  if (analysisStatus === SUCCESS) {
    StatusSeries = _.map(statusesDistribution, function (graphData) {
      return {
        name: graphData?.label,
        value: graphData?.metadata?.count,
      };
    });

    TypeSeries = _.map(typesDistribution, function (graphData) {
      return {
        name: graphData?.label,
        value: graphData?.metadata?.count,
      };
    });

    ClassSeries = _.map(classesDistribution, function (graphData) {
      return {
        name: "Class " + graphData?.label,
        data: [graphData?.metadata?.count],
        uv: graphData?.metadata?.count,
      };
    });

    StateSeries = _.map(statesDistribution, function (graphData) {
      return {
        name: graphData?.label,
        value: graphData?.metadata?.count,
      };
    });

    ProprietorSeries = _.map(proprietorsDistribution, function (graphData) {
      return {
        name: graphData?.label,
        data: [graphData?.metadata?.count],
        uv: graphData?.metadata?.count,
      };
    });

    StatusSeries = _.filter(StatusSeries, function (distribution) {
      return distribution.value !== 0 && distribution.name !== null;
    });

    StateSeries = _.filter(StateSeries, function (distribution) {
      return distribution.value !== 0 && distribution.name !== null;
    });

    TypeSeries = _.filter(TypeSeries, function (distribution) {
      return distribution.value !== 0 && distribution.name !== null;
    });

    ClassSeries = _.filter(ClassSeries, function (distribution) {
      return distribution.data[0] !== 0;
    });

    ProprietorSeries = _.filter(ProprietorSeries, function (distribution) {
      return distribution.data[0] !== 0;
    });
  }

  const chartIdStatus = "new_result_analysis_statusChart";
  const chartIdState = "new_result_analysis_stateChart";
  const chartIdType = "new_result_analysis_typeChart";

  // Reset label positions for this chartId before rendering
  resetLabelPositions(chartIdStatus);
  resetLabelPositions(chartIdState);
  resetLabelPositions(chartIdType);

  return (
    <Row gutter={16}>
      {/* status distribution */}
      <Col className="gutter-row" span={24}>
        <Card
          hoverable
          bodyStyle={{ textAlign: "center", padding: "15px" }}
          loading={analysisStatus === LOADING}
        >
          <h5>Status Distribution</h5>
          <Spin spinning={statusLoading}>
            <div className="recharts-parent-div-custom-style">
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={StatusSeries}
                    label={(props) =>
                      renderPieCustomizedLabel(props, chartIdStatus)
                    }
                    labelLine={false}
                    isAnimationActive={false}
                    fill="#8884d8"
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                  >
                    {StatusSeries.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                    ))}
                  </Pie>
                  <Tooltip
                    formatter={(value) => value}
                    content={(props) =>
                      renderPieCustomTooltip(props, StatusSeries ?? [])
                    }
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </Spin>
        </Card>
      </Col>
      {/* status distribution */}

      {/* state distribution */}
      <Col className="gutter-row m-top-20" span={24}>
        <Card
          hoverable
          bodyStyle={{ textAlign: "center", padding: "15px" }}
          loading={analysisStatus === LOADING}
        >
          <h5>State Distribution</h5>
          <Spin spinning={statesLoading}>
            <div className="recharts-parent-div-custom-style">
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={StateSeries}
                    label={(props) =>
                      renderPieCustomizedLabel(props, chartIdState)
                    }
                    labelLine={false}
                    isAnimationActive={false}
                    fill="#8884d8"
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                  >
                    {StateSeries.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                    ))}
                  </Pie>
                  <Tooltip
                    formatter={(value) => value}
                    content={(props) =>
                      renderPieCustomTooltip(props, StateSeries ?? [])
                    }
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </Spin>
        </Card>
      </Col>
      {/* state distribution */}

      {/* type distribution */}
      <Col className="gutter-row m-top-20" span={24}>
        <Card
          hoverable
          bodyStyle={{ textAlign: "center", padding: "15px" }}
          loading={analysisStatus === LOADING}
        >
          <h5>Type Distribution</h5>
          <Spin spinning={typesLoading}>
            <div className="recharts-parent-div-custom-style">
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={TypeSeries}
                    label={(props) =>
                      renderPieCustomizedLabel(props, chartIdType)
                    }
                    labelLine={false}
                    isAnimationActive={false}
                    fill="#8884d8"
                    dataKey="value"
                    cx="50%"
                    cy="50%"
                  >
                    {TypeSeries.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                    ))}
                  </Pie>
                  <Tooltip
                    formatter={(value) => value}
                    content={(props) =>
                      renderPieCustomTooltip(props, TypeSeries ?? [])
                    }
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </Spin>
        </Card>
      </Col>
      {/* type distribution */}

      {/* class distribution */}
      <Col className="gutter-row m-top-20" span={24}>
        <Card
          hoverable
          bodyStyle={{ textAlign: "center", padding: "15px" }}
          loading={analysisStatus === LOADING}
        >
          <h5>Classes</h5>
          <p>Class-wise Distribution</p>
          <Spin spinning={classLoading}>
            <div className="recharts-parent-div-custom-style">
              <ResponsiveContainer>
                <BarChart data={ClassSeries} margin={{ bottom: 30 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    label={{
                      value: "Classes",
                      offset: -20,
                      position: "insideBottom",
                    }}
                  />
                  <YAxis
                    label={{
                      value: "Trademarks",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Bar dataKey="uv" fill="#8884d8">
                    {ClassSeries.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                    ))}
                  </Bar>
                  <Tooltip
                    cursor={{ fill: "transparent" }}
                    content={renderBarCustomTooltip}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Spin>
        </Card>
      </Col>
      {/* class distribution */}

      {/* proprietor distribution */}
      <Col className="gutter-row m-top-20" span={24}>
        <Card
          hoverable
          bodyStyle={{ textAlign: "center", padding: "15px" }}
          loading={analysisStatus === LOADING}
        >
          <h5>Proprietor Distribution</h5>
          <Spin spinning={proprietorsLoading}>
            <div className="recharts-parent-div-custom-style">
              <ResponsiveContainer>
                <BarChart data={ProprietorSeries} margin={{ bottom: 30 }}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="name"
                    label={{
                      value: "Proprietors",
                      offset: -20,
                      position: "insideBottom",
                    }}
                  />
                  <YAxis
                    label={{
                      value: "Trademarks",
                      angle: -90,
                      position: "insideLeft",
                    }}
                  />
                  <Bar dataKey="uv" fill="#8884d8">
                    {ProprietorSeries.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                    ))}
                  </Bar>
                  <Tooltip
                    cursor={{ fill: "transparent" }}
                    content={renderBarCustomTooltip}
                  />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </Spin>
        </Card>
      </Col>
      {/* proprietor distribution */}
    </Row>
  );
}

export default NewResultsAnalysis;
