import { combineReducers } from "redux";
import manage from "./manage";
import dashboard from "./dashboard";
import dashboardFilters from "./dashboardFilters";
import manageFilters from "./manageFilters";

export default combineReducers({
  dashboard: dashboard,
  manage: manage,
  dashboardFilters: dashboardFilters,
  manageFilters: manageFilters,
});
