import Axios from "axios";
import { onResponseError } from "../../../functions/commonFunctions/interceptor";
import mikeAxios from "../../../configs/mikeAxios";
import { SRCH_RT_URL } from "../../../configs/rootConfigs";
import {
  default_filter_page,
  default_filter_page_size,
} from "../../../components/Watch/watchFilter/utils/utils";
import {
  ERROR,
  LOADING,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";
import {
  UPDATE_SEARCH_FILTER_APPLIED,
  UPDATE_SEARCH_FILTER_DATE_PROPOSED,
  UPDATE_SEARCH_FILTER_DATE_VALUES,
  UPDATE_SEARCH_FILTER_EXCLUDED_VALUES,
  UPDATE_SEARCH_FILTER_RESET_ALL,
  UPDATE_SEARCH_FILTER_SELECTED_VALUES,
  UPDATE_SEARCH_FILTER_INPUT_VALUE,
  UPDATE_SEARCH_ACTIVE_INACTIVE_FILTERS,
  UPDATE_SEARCH_FILTER_APPLY_SEARCH_INPUT,
  UPDATE_SEARCH_FILTER_APPLY_SEARCH_TYPE,
  UPDATE_TRADEMARK_SEARCH_FILTER_APPLIED,
} from "./types";

export function getSearchAllFilters(body) {
  return async (dispatch) => {
    dispatch({
      type: `GET_SEARCH_${body?.required_filter}_FILTERS`,
      status: LOADING,
      filter_key: body?.required_filter ?? "",
    });
    try {
      let params = {
        operation: "FILTERS",
        flash_flag: body?.flash_flag ?? false,
        search_log_ids: body?.search_log_ids,
        search_log_id: body?.search_log_id,
        required_filter: body?.required_filter,
        page: body?.page ?? default_filter_page,
        page_size: default_filter_page_size,
        search_term: body?.search_term ?? "",
      };
      let response = await mikeAxios.get(
        `${SRCH_RT_URL}/new/trademark_search/filters/`,
        {
          params,
        },
      );
      dispatch({
        type: `GET_SEARCH_${body?.required_filter}_FILTERS`,
        status: SUCCESS,
        filter_key: body?.required_filter ?? "",
        payload: response?.data,
        page: body?.page,
      });
    } catch (error) {
      // onResponseError(error);
      const status = error?.response?.status || 0;
      if (status === 400) {
        console.log("handle 400 response error here...");
      }
      dispatch({
        type: `GET_SEARCH_${body?.required_filter}_FILTERS`,
        status: ERROR,
        filter_key: body?.required_filter ?? "",
        payload: error,
      });
    }
  };
}

export function changeSearchFilterSelectedValues(
  filter_key,
  values,
  onlyValues,
) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_FILTER_SELECTED_VALUES,
      filter_key,
      values,
      onlyValues,
    });
  };
}

export function changeSearchFilterExcludedValues(
  filter_key,
  values,
  onlyValues,
) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_FILTER_EXCLUDED_VALUES,
      filter_key,
      values,
      onlyValues,
    });
  };
}

export function changeSearchFilterApplied(value) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_FILTER_APPLIED,
      value,
    });
  };
}

export function resetAllSearchFilter() {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_FILTER_RESET_ALL,
    });
  };
}

export function updateTrademarkSearchFilterApplied(value) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_TRADEMARK_SEARCH_FILTER_APPLIED,
      value,
    });
  };
}

export function updateSearchFilterDateValues(filter_key, values) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_FILTER_DATE_VALUES,
      filter_key,
      values,
    });
  };
}

export function updateSearchFilterDateProposedValues(filter_key, values) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_FILTER_DATE_PROPOSED,
      filter_key,
      values,
    });
  };
}

export function changeSearchFilterInputValue(filter_key, value) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_FILTER_INPUT_VALUE,
      filter_key,
      value,
    });
  };
}

export function changeSearchActiveInActiveStatusFilter(
  filter_key,
  activeOrInActiveKey,
  value,
) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_ACTIVE_INACTIVE_FILTERS,
      filter_key,
      activeOrInActiveKey,
      value,
    });
  };
}

export function changeSearchDashboardSearchValue(key, value) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_FILTER_APPLY_SEARCH_INPUT,
      key,
      value,
    });
  };
}

export function changeSearchDashboardSearchType(value) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_SEARCH_FILTER_APPLY_SEARCH_TYPE,
      value,
    });
  };
}
