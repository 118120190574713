import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import { Route } from "react-router-dom";
import { Layout, Row, Col } from "antd";

import PatentDashboard from "./YourPatent/Patent-dashboard";
import Patent from "./YourPatent/Patent";
import ReplyToFer from "./YourPatent/Reply-to-fer";

import CustomPatent from "./DocketedPatent/Custom-Patent";
import CustomPatentProfile from "./DocketedPatent/Custom-Patent-Profile";
import InternationalPatent from "./DocketedPatent/International-Patent";
import InternationalPatentProfile from "./DocketedPatent/International-Patent-Profile";

import PatentProfile from "./Patent-Profile";
import ApplicantProfile from "./Applicant-Profile";
import InventorProfile from "./Inventor-Profile";
import ManagePatent from "./ManagePatent";
import PatentManageProfile from "./PatentManageProfile";
import UnPublishedPatentDashboard from "./UnPublishedPatent/Dashboard/UnPublishedPatentDashboard";
import ManageUnPublishedPatent from "./UnPublishedPatent/Manage/ManageUnPublishedPatent";
import UnPublishPatentDetail from "./UnPublishedPatent/Detail/UnPublishPatentDetail";

const { Content } = Layout;

class PatentLayout extends Component {
  render() {
    return (
      <div>
        <Layout className="Patent">
          <Content style={{ margin: "0px" }}>
            <Row
              className="content-container vertical_scroll"
              style={{
                height: `${this.props.managerAlert.length > 0 ? "calc(100vh - 40px)" : "calc(100vh - 0px)"}`,
              }}
            >
              <Col span={24}>
                <Route
                  path="/manager/patent/your-patent/dashboard"
                  component={PatentDashboard}
                />
                <Route
                  path="/manager/patent/your-patent/total-marks"
                  component={Patent}
                />
                <Route
                  path="/manager/patent/your-patent/reply-to-fer"
                  component={ReplyToFer}
                />
                <Route
                  path="/manager/patent/your-patent/manage-patent"
                  component={ManagePatent}
                />

                <Route
                  path="/manager/patent/unpublished-patent/dashboard"
                  component={UnPublishedPatentDashboard}
                />
                <Route
                  path="/manager/patent/unpublished-patent/manage"
                  component={ManageUnPublishedPatent}
                />
                <Route
                  path="/manager/patent/unpublished-patent/patent-dashboard/:patentId"
                  component={UnPublishPatentDetail}
                />

                <Route
                  path="/manager/patent/docketed-patent/custom-patent"
                  component={CustomPatent}
                />
                <Route
                  path="/manager/patent/docketed-patent/custom-patent-profile/"
                  component={CustomPatentProfile}
                />
                <Route
                  path="/manager/patent/docketed-patent/international-patent"
                  component={InternationalPatent}
                />
                <Route
                  path="/manager/patent/docketed-patent/international-patent-profile/"
                  component={InternationalPatentProfile}
                />

                <Route
                  path="/manager/patent/patent-profile"
                  component={PatentProfile}
                />
                <Route
                  path="/manager/patent/applicant-profile"
                  component={ApplicantProfile}
                />
                <Route
                  path="/manager/patent/inventor-profile"
                  component={InventorProfile}
                />

                <Route
                  path="/manager/patent/manage-patent-profile"
                  component={PatentManageProfile}
                />
              </Col>
            </Row>
          </Content>
        </Layout>
      </div>
    );
  }
}

const mapDispatchToProps = {};

function mapStateToProps(state) {
  return deepFreeze({
    managerAlert: state.alertMessages.manager,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(PatentLayout);
