import { Col, Row, Tabs, Typography } from "antd";
import React from "react";

const { Title } = Typography;

const HeaderSection = (props) => {
  const { tabItems = [], onTabChange } = props;
  const header = <Title level={3}>Dashboard</Title>;

  const tabSection = (
    <Tabs defaultActiveKey="1" items={tabItems} onChange={onTabChange} />
  );

  return (
    <div>
      <Row>
        <Col>{header}</Col>
      </Row>
      <Row>
        <Col span={24}>{tabSection}</Col>
      </Row>
    </div>
  );
};

export default HeaderSection;
