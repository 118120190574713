//--------All Classes Array--------
export const allClasses = Array.from(Array(46).keys()).slice(1);

//--------Refresh Time Period Constant--------
export const refreshPeriod = 7000000;

export const allClassesObject = Array.from({ length: 45 }, (_, i) => ({
  label: String(i + 1),
  value: i + 1,
  metadata: {
    count: 0,
  },
}));
