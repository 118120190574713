import React from "react";
import { useDispatch } from "react-redux";
import CommonSelectWithExcludeFilter from "../../../../commonComponents/filters/CommonSelectWithExcludeFilter";
import {
  changeUnPublishedPatentFilterExcludeValues,
  changeUnPublishedPatentFilterSearchValues,
  changeUnPublishedPatentFilterSelectedValues,
  getManageUnPublishedPatentFilter,
} from "../../../../../../redux/actions/managerActions/unPublishedPatentDashboard/managePatent";
import { debounce } from "lodash";

const SelectWithExcludeFilterContainer = (props) => {
  const { filteredData, key, loading } = props;
  const dispatch = useDispatch();

  const handleChange = (newValue, newOption) => {
    const tempObject = newOption.map((item) => ({
      label: item.label,
      value: item.value,
    }));
    const tempValues = newOption.map((item) => item.value);
    dispatch(
      changeUnPublishedPatentFilterSelectedValues(
        filteredData.key,
        tempObject,
        tempValues,
      ),
    );
  };

  const handleSearchDebounced = React.useCallback(
    debounce(async (newValue) => {
      let body = {
        filter_key: filteredData.key,
        search_term: newValue,
      };
      dispatch(getManageUnPublishedPatentFilter(body));
    }, 500),
    [],
  );

  const handleSearch = (newValue) => {
    dispatch(
      changeUnPublishedPatentFilterSearchValues(filteredData.key, newValue),
    );
    handleSearchDebounced(newValue);
  };

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    if (filteredData.data.length === filteredData.metadata.count) {
      return;
    }
    if (
      scrollTop + clientHeight >= scrollHeight - 20 &&
      filteredData.data.length < filteredData.metadata.count &&
      !filteredData.loading
    ) {
      // handle pagination for individual filters here  ------------------------------
      let body = {
        filter_type: filteredData.key,
        page: filteredData.metadata.page + 1,
        search_term: filteredData?.searchValue ?? "",
      };
      dispatch(getManageUnPublishedPatentFilter(body));
      // handleGetSingleFilter?.(body);
    }
  };

  const handleExcludeButton = (event, item) => {
    event.stopPropagation();

    // Combine all data (both selected and excluded values)
    let allData = [...filteredData.data, ...filteredData?.excludeValues] || [];

    // Check if the item is already excluded
    const isExcluded = filteredData.excludeValues.some(
      (excludedItem) => excludedItem.value === item.value,
    );

    let updatedExcludeValues;

    if (isExcluded) {
      // If the item is already excluded, remove it from excludeValues
      updatedExcludeValues = filteredData.excludeValues.filter(
        (excludedItem) => excludedItem.value !== item.value,
      );
    } else {
      // If the item is not excluded, add it to excludeValues
      updatedExcludeValues = [
        ...filteredData.excludeValues,
        allData.find((dataItem) => dataItem.value === item.value),
      ];
    }

    // Ensure the updated excludeValues array is unique and doesn't duplicate any items
    const uniqueExcludeValues = updatedExcludeValues.reduce((unique, obj) => {
      return unique.some((item) => String(item.value) === String(obj.value))
        ? unique
        : [...unique, obj];
    }, []);
    const tempValues = uniqueExcludeValues.map((item) => item.value);

    // Dispatch the action to update the excluded values in the global state
    dispatch(
      changeUnPublishedPatentFilterExcludeValues(
        filteredData.key,
        uniqueExcludeValues,
        tempValues,
      ),
    );
  };

  const showExtraChildren = () => {
    switch (filteredData?.key) {
      default:
        return null;
    }
  };

  return (
    <CommonSelectWithExcludeFilter
      {...props}
      filteredData={filteredData}
      loading={loading}
      handleChange={handleChange}
      handleSearch={handleSearch}
      handleScroll={handleScroll}
      value={filteredData.selectedValues}
      exclude={filteredData.excludeValues}
      tableView={props?.tableView ?? false}
      rename=""
      extraHighlight={
        filteredData?.selectedValues?.length ||
        filteredData?.excludeValues?.length
      }
      filterId={key}
      handleExcludeButton={handleExcludeButton}
      extraChildren={showExtraChildren()}
    />
  );
};

export default SelectWithExcludeFilterContainer;
