import { Card, Col, DatePicker, Empty, Row } from "antd";
import dayjs from "dayjs";
import React from "react";
import { checkValue } from "../../../../../functions/commonFunctions/checkValueFunctions";

const individualColumnStyle = {
  border: "1px solid #dee2e6",
  paddingTop: "2px",
  paddingRight: "5px",
  paddingBottom: "2px",
  paddingLeft: "5px",
  display: "flex",
  alignItems: "center",
};

const labelStyling = {
  fontWeight: "500",
};

const DeadlineSection = (props) => {
  const {
    deadlineData,
    handleDateChange,
    loading,
    showHeading = true,
    maxHeight = "100%",
  } = props;

  // Component for deadline rows with two dates
  const DeadlineRow = ({
    singleKey,
    label,
    dueDate,
    compliedDate,
    onDateChange,
  }) => {
    return (
      <Row gutter={[16, 16]} key={singleKey}>
        <Col
          xs={24}
          sm={8}
          style={{ ...individualColumnStyle, ...labelStyling }}
        >
          {label}
        </Col>
        <Col xs={24} sm={8} style={individualColumnStyle}>
          {dueDate}
        </Col>
        <Col xs={24} sm={8} style={individualColumnStyle}>
          <DatePicker
            value={
              checkValue(compliedDate)
                ? dayjs(compliedDate, "DD/MM/YYYY")
                : null
            }
            key={singleKey}
            format={"DD/MM/YYYY"}
            onChange={onDateChange}
          />
        </Col>
      </Row>
    );
  };

  return (
    <Card
      title={showHeading ? "Deadlines" : null}
      headStyle={{ background: "#d9dcdf" }}
      style={{ maxHeight: maxHeight, overflowY: "scroll" }}
      loading={loading}
    >
      {deadlineData?.length ? (
        <Row gutter={[16, 16]} style={{ height: "50px" }}>
          <Col xs={24} sm={8} style={individualColumnStyle}>
            <strong>Deadlines</strong>
          </Col>
          <Col xs={24} sm={8} style={individualColumnStyle}>
            <strong>Due Date</strong>
          </Col>
          <Col xs={24} sm={8} style={individualColumnStyle}>
            <strong>Complied Date</strong>
          </Col>
        </Row>
      ) : null}
      {deadlineData?.length ? (
        deadlineData.map(
          ({ key, deadline_type, due_date, complied_date, id }) => (
            <DeadlineRow
              key={key}
              singleKey={key}
              label={deadline_type}
              dueDate={due_date}
              compliedDate={complied_date}
              onDateChange={(values) => handleDateChange(values, id)}
            />
          ),
        )
      ) : (
        <Empty />
      )}
    </Card>
  );
};

export default DeadlineSection;
