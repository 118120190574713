import {
  ERROR,
  LOADING,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";
import {
  UNPUBLISHED_PATENT_application_types_FILTER,
  UNPUBLISHED_PATENT_applicants_FILTER,
  UNPUBLISHED_PATENT_inventors_FILTER,
  UNPUBLISHED_PATENT_application_offices_FILTER,
  UPDATE_UNPUBLISED_PATENT_RESET_ALL_FILTERS,
  UPDATE_UNPUBLISHED_PATENT_FILTER_APPLIED,
  UPDATE_UNPUBLISHED_PATENT_FILTER_EXCLUDED_VALUE,
  UPDATE_UNPUBLISHED_PATENT_FILTER_SEARCH_VALUE,
  UPDATE_UNPUBLISHED_PATENT_FILTER_SELECTED_VALUE,
  UPDATE_UNPUBLISHED_PATENT_GLOBAL_SEARCH_VALUE,
  UPDATE_UNPUBLISHED_PATENT_DATE_FILTER_VALUE,
} from "../../../actions/managerActions/unPublishedPatentDashboard/types";

const initialState = {
  inventorsConfig: {
    key: "inventors",
    menu_label: "Inventors",
    data: [],
    AndOrButton: {
      show: true,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    onlyValues: [],
    excludeValues: [],
    onlyExcludedValues: [],
    searchValue: "",
  },
  application_officesConfig: {
    key: "application_offices",
    menu_label: "Application Offices",
    data: [],
    AndOrButton: {
      show: true,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    onlyValues: [],
    excludeValues: [],
    onlyExcludedValues: [],
    searchValue: "",
  },
  application_typesConfig: {
    key: "application_types",
    menu_label: "Application Type",
    data: [],
    AndOrButton: {
      show: true,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    onlyValues: [],
    excludeValues: [],
    onlyExcludedValues: [],
    searchValue: "",
  },
  applicantsConfig: {
    key: "applicants",
    menu_label: "Applicant",
    data: [],
    AndOrButton: {
      show: true,
    },
    metadata: {
      count: 0,
      page: 1,
    },
    loading: false,
    selectedValues: [],
    onlyValues: [],
    excludeValues: [],
    onlyExcludedValues: [],
    searchValue: "",
  },
  date_of_priorityConfig: {
    key: "date_of_priority",
    value: {},
  },
  filing_dateConfig: {
    key: "filing_date",
    value: {},
  },
  due_dateConfig: {
    key: "due_date",
    value: {},
  },
  globalSearchValue: "",
  isFilterApplied: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UNPUBLISHED_PATENT_application_types_FILTER:
    case UNPUBLISHED_PATENT_applicants_FILTER:
    case UNPUBLISHED_PATENT_inventors_FILTER:
    case UNPUBLISHED_PATENT_application_offices_FILTER: {
      const configKey = `${action.filter_key}Config`;
      // Check if the filter_key exists in the state
      if (!state[configKey]) {
        return state; // Return the state unchanged if the config doesn't exist
      }

      switch (action.status) {
        case SUCCESS: {
          return {
            ...state,
            [configKey]: {
              ...state[configKey],
              loading: false,
              data: action.payload?.data || [],
              metadata: action.payload?.metadata || state[configKey].metadata,
            },
          };
        }
        case LOADING: {
          return {
            ...state,
            [configKey]: {
              ...state[configKey],
              loading: true,
            },
          };
        }
        case ERROR: {
          return {
            ...state,
            [configKey]: {
              ...state[configKey],
              loading: false,
            },
          };
        }

        default:
          return state;
      }
    }

    case UPDATE_UNPUBLISHED_PATENT_FILTER_SELECTED_VALUE: {
      const configKey = `${action.filter_key}Config`;
      // Check if the filter_key exists in the state
      if (!state[configKey]) {
        return state; // Return the state unchanged if the config doesn't exist
      }
      return {
        ...state,
        [configKey]: {
          ...state[configKey],
          selectedValues: action?.values ?? [],
          onlyValues: action?.onlyValues ?? [],
        },
      };
    }

    case UPDATE_UNPUBLISHED_PATENT_FILTER_EXCLUDED_VALUE: {
      const configKey = `${action.filter_key}Config`;
      // Check if the filter_key exists in the state
      if (!state[configKey]) {
        return state; // Return the state unchanged if the config doesn't exist
      }

      return {
        ...state,
        [configKey]: {
          ...state[configKey],
          excludeValues: action?.values ?? [],
          onlyExcludedValues: action?.onlyValues ?? [],
        },
      };
    }

    case UPDATE_UNPUBLISHED_PATENT_FILTER_SEARCH_VALUE: {
      const configKey = `${action.filter_key}Config`;
      // Check if the filter_key exists in the state
      if (!state[configKey]) {
        return state; // Return the state unchanged if the config doesn't exist
      }
      return {
        ...state,
        [configKey]: {
          ...state[configKey],
          searchValue: action.value ?? "",
        },
      };
    }

    case UPDATE_UNPUBLISED_PATENT_RESET_ALL_FILTERS: {
      return {
        ...state,
        inventorsConfig: {
          ...state.inventorsConfig,
          selectedValues: [],
          onlyValues: [],
          excludeValues: [],
          onlyExcludedValues: [],
          searchValue: "",
        },
        application_officesConfig: {
          ...state.application_officesConfig,
          selectedValues: [],
          onlyValues: [],
          excludeValues: [],
          onlyExcludedValues: [],
          searchValue: "",
        },
        application_typesConfig: {
          ...state.application_typesConfig,
          selectedValues: [],
          onlyValues: [],
          excludeValues: [],
          onlyExcludedValues: [],
          searchValue: "",
        },
        applicantsConfig: {
          ...state.applicantsConfig,
          selectedValues: [],
          onlyValues: [],
          excludeValues: [],
          onlyExcludedValues: [],
          searchValue: "",
        },
        date_of_priorityConfig: {
          ...state.date_of_priorityConfig,
          value: {},
        },
        filing_dateConfig: {
          ...state.filing_dateConfig,
          value: {},
        },
        due_dateConfig: {
          ...state.due_dateConfig,
          value: {},
        },
        globalSearchValue: "",
        isFilterApplied: false,
      };
    }

    case UPDATE_UNPUBLISHED_PATENT_FILTER_APPLIED: {
      return {
        ...state,
        isFilterApplied: action.value,
      };
    }

    case UPDATE_UNPUBLISHED_PATENT_GLOBAL_SEARCH_VALUE: {
      return {
        ...state,
        globalSearchValue: action.value,
      };
    }

    case UPDATE_UNPUBLISHED_PATENT_DATE_FILTER_VALUE: {
      const configKey = `${action.filter_key}Config`;
      // Check if the filter_key exists in the state
      if (!state[configKey]) {
        return state; // Return the state unchanged if the config doesn't exist
      }

      return {
        ...state,
        [configKey]: {
          ...state[configKey],
          value: action?.values ?? {},
        },
      };
    }

    default:
      return state;
  }
}
