const sampleData = {
  data: [
    {
      id: 5829167,
      applied_for: "MikeLegal",
      application_number: 3740893,
      status: "Registered",
      associated_image: null,
      application_date: "31/01/2018",
      date_of_usage: "01/12/2016",
      associated_class: [45],
      proprietor: [
        {
          id: 3992367,
          name: "Mikelegal Services Private Limited",
          address: "Town House, D-1 Uniworld City Sector-30, Gurgaon",
        },
      ],
      type: "Word Mark",
      description:
        "[CLASS : 45] Legal research and technical and strategy support services, namely, providing interactive information and research databases via the Internet for legal consultation, legal counseling, competitive and legal research; trademark watch services; providing comprehensive intellectual property information as to the use, status, history and ownership of trademarks and company names, to be used as an aid to trademark and company name selection, review, and protection; providing a database of patent status information.",
    },
    {
      id: 5743619,
      applied_for: "FIDELEGAL (DEVICE)",
      application_number: 2174371,
      status: "Registered",
      associated_image:
        "https://miketm-prod.s3.amazonaws.com/TrademarkImages/2174371.png",
      application_date: "13/07/2011",
      date_of_usage: "01/06/2010",
      associated_class: [45],
      proprietor: [
        {
          id: 6040950,
          name: "Sumitgahlawat",
          address: "D-58, L.G.F., Panchsheel Enclave, New Delhi- 110017",
        },
      ],
      type: "Device Mark",
      description: "[CLASS : 45] PROVIDING\r\nLEGAL SERVICES",
    },
    {
      id: 3126761,
      applied_for: "Fidelegal Advocates & Solicitors",
      application_number: 2442850,
      status: "Registered",
      associated_image: null,
      application_date: "14/12/2012",
      date_of_usage: "01/06/2010",
      associated_class: [45],
      proprietor: [
        {
          id: 5426092,
          name: "Sumit Gahlawat",
          address: "D-58, L.G.F., Panchsheel Enclave, New Delhi — 110017",
        },
      ],
      type: "Word Mark",
      description: "[CLASS : 45] PROVIDING COMPLETE LEGAL SERVICES.",
    },
    {
      id: 7971424,
      applied_for: "Takelegal.in",
      application_number: 5446607,
      status: "Registered",
      associated_image:
        "https://miketm-prod.s3.amazonaws.com/TrademarkImages/5446607.png",
      application_date: "13/05/2022",
      date_of_usage: "24/03/2022",
      associated_class: [35],
      proprietor: [
        {
          id: 5544217,
          name: "Worknex Global Pvt Ltd",
          address:
            "B7-404, Camelot Royale Housing Society, Viman Nagar, Pune- 411014",
        },
      ],
      type: "Device Mark",
      description:
        "[CLASS : 35] Provision of a unified platform for buyers And sellers of Products and Services.",
    },
    {
      id: 7113376,
      applied_for: "5IVE LEGAL",
      application_number: 4588165,
      status: "Registered",
      associated_image: null,
      application_date: "29/07/2020",
      date_of_usage: null,
      associated_class: [45],
      proprietor: [
        {
          id: 5119912,
          name: "R. Prashanth",
          address:
            "68, Gangadeeswarar Koil Street, 1St Lane, Puraswalkam, Chennai – 600 084",
        },
      ],
      type: "Word Mark",
      description:
        "[CLASS : 45] Legal services; Security services for the physical protection of tangible property and individuals; Personal and social services rendered by others to meet the needs of individuals",
    },
    {
      id: 3676379,
      applied_for: "MGK Legal",
      application_number: 3461352,
      status: "Registered",
      associated_image: null,
      application_date: "20/01/2017",
      date_of_usage: "17/08/2011",
      associated_class: [45],
      proprietor: [
        {
          id: 3315101,
          name: "Sameer Madhav Khale",
          address:
            "Flat No 2 Tridal 1206/35/B, J.M. Road, Shivajinagar, Pune, Maharashtra 411004",
        },
      ],
      type: "Word Mark",
      description:
        "[CLASS : 45] PROVIDING LEGAL SERVICES INCLUDING PROFESSIONAL LEGAL SERVICES, PROFESSIONAL CONSULTANCY SERVICES, CORPORATE AND COMMERCIAL LEGAL SERVICES, LITIGATION SERVICES, LEGAL SERVICES IN REAL ESTATE, ARBITRATION, MEDIATION, SERVICES RENDERED BY LAWYERS TO INDIVIDUALS, GROUPS OF INDIVIDUALS, ORGANIZATIONS AND ENTERPRISES, INTELLECTUAL PROPERTY CONSULTANT AND LITIGATION SERVICES, LEGAL RESEARCH AND ALL OTHER ACTIVITIES INCLUDED IN CLASS 45.",
    },
    {
      id: 7524113,
      applied_for: "Device of ML Modi-fide Legal",
      application_number: 4999172,
      status: "Registered",
      associated_image:
        "https://miketm-prod.s3.amazonaws.com/TrademarkImages/4999172.png",
      application_date: "09/06/2021",
      date_of_usage: "20/10/2020",
      associated_class: [45],
      proprietor: [
        {
          id: 5324855,
          name: "Divay Prakash Modi",
          address:
            "Plot No, 34, Vedh Vatika, Nsr, Sodala, Jaipur - 302019, Rajasthan",
        },
      ],
      type: "Device Mark",
      description:
        "[CLASS : 45] LEGAL SERVICES INCLUDING CONSULTANCY, ADVOCACY, LITIGATION SERVICES, HANDLING LEGAL FORMALITIES, CONVEYANCING, PARALEGAL SERVICES; PATENT, TRADE MARK, DESIGN AND COPYRIGHT AGENCY SERVICES; ESTABLISHMENT, MAINTENANCE AND MANAGEMENT OF DOMAIN NAME REGISTRATIONS, CONSULTANCY, PROTECTION, MANAGEMENT, LICENSING, WATCHING, AGENCY SERVICES, ENFORCEMENT, INVESTIGATIONS, GRANTING OF LICENSE, CONTRACTUAL, LEGAL RESEARCH, MONITORING SERVICES.",
    },
  ],
  success: true,
  metadata: {
    count: 7,
    search_log_id: 7813616,
  },
};

export default sampleData;
