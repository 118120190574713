import { InboxOutlined } from "@ant-design/icons";
import {
  Button,
  Collapse,
  Form,
  Modal,
  notification,
  Typography,
  Upload,
} from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { bulkAddUnPublishedData } from "../../../../../../redux/actions/managerActions/unPublishedPatentDashboard/managePatent";
import ClientBulkErrorScreen from "../../../../../commonComponents/MasterComponents/ClientMaster/BulkUpload/ClientBulkErrorScreen";

const { Dragger } = Upload;
const { Panel } = Collapse;
const { Link } = Typography;

const sample_bulk_excel_file =
  "https://miketm-prod.s3.ap-south-1.amazonaws.com/Sample/UnpublishedPatents.xlsx";

const BulkUpload = (props) => {
  const { open, setOpen } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [errorList, setErrorList] = useState([]);
  const [openDetailListPopUp, setOpenDetailListPopUp] = useState(false);
  const [detailList, setDetailList] = useState({});

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    setLoading(true);
    if (!file) {
      notification["error"]({
        message: "Please upload a file!",
      });
      return;
    }
    const formData = new FormData();
    formData.append("method", "EXCEL");
    formData.append("file", file);

    dispatch(
      bulkAddUnPublishedData(formData, {
        onSuccess: (successResponse) => {
          onClose();
          setLoading(false);
          props?.getUnPublishedPatentData?.();
          notification["success"]({
            message: successResponse?.message ?? "File Uploaded Successfully!",
          });
        },
        onError: (errorResponse) => {
          setLoading(false);
          notification["error"]({
            message: errorResponse?.message ?? "Something went wrong!",
          });
          if (errorResponse?.payload?.length) {
            setDisableButton(true);
          }
          errorResponse?.payload?.length
            ? setErrorList(errorResponse?.payload)
            : setErrorList([]);
        },
      }),
    );
  };

  const draggerProps = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      setFile(file);
      setDisableButton(false);
      setErrorList([]);
      return false;
    },
    fileList: file ? [file] : [],
    onRemove: () => {
      setFile(null);
    },
    accept: ".xls,.xlsx",
  };

  const errorDescription = (
    <div style={{ maxHeight: "100px", overflowY: "auto" }}>
      <ul>
        {errorList.map((error, index) => (
          <li key={index}>{error}</li>
        ))}
      </ul>
    </div>
  );

  const customErrorPanelStyle = {
    backgroundColor: "#FFF2F0",
    border: "1px solid red",
  };

  const customErrorHeaderStyle = {
    color: "red",
  };

  const loadingArea = (
    <div style={{ ...customErrorPanelStyle, padding: "10px" }}>
      <div style={customErrorHeaderStyle}>
        We are validating your request. Please do not close this window or
        refresh this page.
      </div>
    </div>
  );

  const handleViewInLargeScreenClicked = (e, type) => {
    e.stopPropagation();
    setOpenDetailListPopUp(true);
    switch (type) {
      case "error":
        {
          setDetailList({
            type: "error",
            heading: "Errors in current excel.",
            list: errorList,
          });
        }
        break;
      //   case "warning":
      // 	{
      // 	  setDetailList({
      // 		type: "warning",
      // 		heading: "Warnings in current excel.",
      // 		list: warningList,
      // 	  });
      // 	}
      // 	break;
      default:
        return null;
    }
  };

  const errorArea = (
    <div style={{ margin: "10px 0px" }}>
      <Collapse
        defaultActiveKey={errorList?.length ? "1" : "0"}
        expandIconPosition={"start"}
      >
        <Panel
          header={
            <div style={customErrorHeaderStyle}>Errors in current excel.</div>
          }
          key="1"
          extra={
            <div style={customErrorHeaderStyle}>
              {errorList?.length ?? 0}{" "}
              {errorList?.length ? (
                <Link
                  onClick={(event) =>
                    handleViewInLargeScreenClicked(event, "error")
                  }
                  style={{ color: "blue" }}
                >
                  ( View in large screen )
                </Link>
              ) : null}
            </div>
          }
          style={customErrorPanelStyle}
        >
          {errorDescription}
        </Panel>
      </Collapse>
    </div>
  );

  const propsForErrorScreen = {
    data: detailList,
    open: openDetailListPopUp,
    setOpen: setOpenDetailListPopUp,
  };

  return (
    <>
      {openDetailListPopUp && (
        <ClientBulkErrorScreen {...propsForErrorScreen} />
      )}
      <Modal
        title="Bulk Upload"
        centered
        open={open}
        onCancel={loading ? null : onClose}
        footer={[
          <Button
            key="cancel"
            disabled={loading}
            type="default"
            onClick={onClose}
          >
            Cancel
          </Button>,
          <Button
            loading={loading}
            key="submit"
            type="primary"
            disabled={!file || disableButton}
            onClick={() => form.submit()}
          >
            {"Upload"}
          </Button>,
        ]}
        bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
        width={800}
        closable={!loading}
      >
        <Form
          form={form}
          id="bulk-upload"
          layout="vertical"
          style={{ margin: "15px 10px" }}
          onFinish={handleSubmit}
          initialValues={""}
        >
          <Form.Item
            name="attachment"
            valuePropName="file"
            getValueFromEvent={(e) => e.file}
            rules={[
              {
                required: true,
                message: "Please upload a file!",
                validator: (_, value) =>
                  file
                    ? Promise.resolve()
                    : Promise.reject("Please upload a file!"),
              },
            ]}
          >
            <Dragger {...draggerProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Click or drag file to this area to upload
              </p>
              <p className="ant-upload-hint">
                Please download sample file first for better overview.
              </p>
            </Dragger>
            <div style={{ marginTop: "10px" }}>
              <a href={sample_bulk_excel_file}>Download Sample Excel</a>
            </div>
            {loading ? loadingArea : null}
            {errorList?.length ? errorArea : null}
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default BulkUpload;
