import {
  ERROR,
  LOADING,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";
import {
  GET_CALENDAR,
  GET_RENEWAL,
  REMOVE_CALENDAR,
  SET_STORE,
} from "../../actions/managerActions/calendar/types";

const defaultState = {
  events: [],
  eventStatus: null,
  activeCalendars: {
    TODO_TASKS: true,
    TRADEMARK_RENEWALS: false,
    TRADEMARK_PENDING_REPLIES: false,
    TRADEMARK_TLA_HEARINGS_CAUSE_LIST: false,
    TRADEMARK_TLA_HEARINGS_IP_INDIA: false,
    TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST: false,
    TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA: false,
    THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES: false,
    THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST: false,
    THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA: false,
    THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST: false,
    THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA: false,
    PATENT_RENEWAL: false,
    PATENT_HEARING: false,
    PATENT_PENDING_REPLIES_TO_FER: false,
    PATENT_PENDING_WORKING_OF_PATENT: false,
    PATENT_PENDING_STATEMENT_OF_UNDERTAKING: false,
    PATENT_PENDING_REQUEST_EXAMINATION_NOTICE: false,
    TRADEMARK_PENDING_REPLIES_DISPATCH_DATE: false,
    THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE: false,
    UNPUBLISHED_PATENT_FORM_18: false,
    UNPUBLISHED_PATENT_PROVISIONAL_TO_COMPLETE: false,
    UNPUBLISHED_PATENT_PROOF_OF_RIGHT_FORM_1: false,
    UNPUBLISHED_PATENT_FORM_3: false,
    UNPUBLISHED_PATENT_FORM_26: false,
    UNPUBLISHED_PATENT_DUE_DATE_FOR_FILING: false,
    UNPUBLISHED_PATENT_PCT_CONVENTION: false,
  },

  TODO_TASKS: null,
  TRADEMARK_RENEWALS: null,
  TRADEMARK_PENDING_REPLIES: null,
  TRADEMARK_TLA_HEARINGS_CAUSE_LIST: null,
  TRADEMARK_TLA_HEARINGS_IP_INDIA: null,
  TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST: null,
  TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA: null,
  THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES: null,
  STATUS_TRACKER_MARKS_OPPOSITION_DEADLINE: null,
  THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LIST: null,
  THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIA: null,
  THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LIST: null,
  THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIA: null,
  PATENT_RENEWAL: null,
  PATENT_HEARING: null,
  PATENT_PENDING_REPLIES_TO_FER: null,
  PATENT_PENDING_WORKING_OF_PATENT: null,
  PATENT_PENDING_STATEMENT_OF_UNDERTAKING: null,
  PATENT_PENDING_REQUEST_EXAMINATION_NOTICE: null,
  TRADEMARK_PENDING_REPLIES_DISPATCH_DATE: null,
  THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATE: null,

  UNPUBLISHED_PATENT_FORM_18: null,
  UNPUBLISHED_PATENT_PROVISIONAL_TO_COMPLETE: null,
  UNPUBLISHED_PATENT_PROOF_OF_RIGHT_FORM_1: null,
  UNPUBLISHED_PATENT_FORM_3: null,
  UNPUBLISHED_PATENT_FORM_26: null,
  UNPUBLISHED_PATENT_DUE_DATE_FOR_FILING: null,
  UNPUBLISHED_PATENT_PCT_CONVENTION: null,

  TODO_TASKSStatus: "SUCCESS",
  TRADEMARK_RENEWALSStatus: "SUCCESS",
  TRADEMARK_PENDING_REPLIESStatus: "SUCCESS",
  TRADEMARK_TLA_HEARINGS_CAUSE_LISTStatus: "SUCCESS",
  TRADEMARK_TLA_HEARINGS_IP_INDIAStatus: "SUCCESS",
  TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LISTStatus: "SUCCESS",
  TRADEMARK_OPPOSITION_HEARINGS_IP_INDIAStatus: "SUCCESS",
  THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIESStatus: "SUCCESS",
  STATUS_TRACKER_MARKS_OPPOSITION_DEADLINEStatus: "SUCCESS",
  THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_CAUSE_LISTStatus: "SUCCESS",
  THIRD_PARTY_OPPOSED_TRADEMARK_TLA_HEARINGS_IP_INDIAStatus: "SUCCESS",
  THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_CAUSE_LISTStatus: "SUCCESS",
  THIRD_PARTY_OPPOSED_TRADEMARK_OPPOSITION_HEARINGS_IP_INDIAStatus: "SUCCESS",
  PATENT_RENEWALStatus: "SUCCESS",
  PATENT_HEARINGStatus: "SUCCESS",
  PATENT_PENDING_REPLIES_TO_FERStatus: "SUCCESS",
  PATENT_PENDING_WORKING_OF_PATENTStatus: "SUCCESS",
  PATENT_PENDING_STATEMENT_OF_UNDERTAKINGStatus: "SUCCESS",
  PATENT_PENDING_REQUEST_EXAMINATION_NOTICEStatus: "SUCCESS",

  TRADEMARK_PENDING_REPLIES_DISPATCH_DATEStatus: "SUCCESS",
  THIRD_PARTY_OPPOSED_TRADEMARK_PENDING_REPLIES_DISPATCH_DATEStatus: " SUCCESS",

  UNPUBLISHED_PATENT_FORM_18Status: "SUCCESS",
  UNPUBLISHED_PATENT_PROVISIONAL_TO_COMPLETEStatus: "SUCCESS",
  UNPUBLISHED_PATENT_PROOF_OF_RIGHT_FORM_1Status: "SUCCESS",
  UNPUBLISHED_PATENT_FORM_3Status: "SUCCESS",
  UNPUBLISHED_PATENT_FORM_26Status: "SUCCESS",
  UNPUBLISHED_PATENT_DUE_DATE_FOR_FILINGStatus: "SUCCESS",
  UNPUBLISHED_PATENT_PCT_CONVENTIONStatus: "SUCCESS",

  currentDate: null,
  start: null,
  end: null,
  YOUR_TM_checkAll: false,
  YOUR_TM_indeterminate: false,

  THIRD_PARTY_checkAll: false,
  THIRD_PARTY_indeterminate: false,

  PATENT_checkAll: false,
  PATENT_indeterminate: false,

  PATENT_unpublished_indeterminate: false,
  PATENT_unpublished_checkAll: false,

  loading: [],
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case GET_CALENDAR:
      if (action.status === "LOADING") {
        return {
          ...state,
          [`${action?.key}Status`]: action.status,
        };
      }
      return {
        ...state,
        [action?.key]: action.payload?.data,
        [`${action?.key}Status`]: action.status,
        events: action.payload?.data
          ? [...state.events, ...action.payload?.data]
          : state.events,
      };
    case REMOVE_CALENDAR:
      return {
        ...state,
        [action.payload]: [],
      };
    case SET_STORE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}
