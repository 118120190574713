export const applicationTypeList = [
  {
    value: "ORDINARY_PROVISIONAL",
    label: "Ordinary Provisional",
  },
  {
    value: "ORDINARY_COMPLETE",
    label: "Ordinary Complete",
  },
  {
    value: "PARIS_CONVENTION",
    label: "Paris Convention",
  },
  {
    value: "PCT_NATIONAL_PHASE",
    label: "PCT National Phase",
  },
  {
    value: "DIVISIONAL",
    label: "Divisional",
  },
];

export const applicationOfficeList = [
  {
    value: "delhi",
    label: "Delhi",
  },
  {
    value: "mumbai",
    label: "Mumbai",
  },
  {
    value: "kolkata",
    label: "Kolkata",
  },
  {
    value: "chennai",
    label: "Chennai",
  },
];

export const applicationStatusList = [
  {
    value: "Application Not Yet Filed",
    label: "Application Not Yet Filed",
  },
  {
    value: "Application Filed",
    label: "Application Filed",
  },
  {
    value: "Provisional Filed",
    label: "Provisional Filed",
  },
  {
    value: "Request for Examination Filed",
    label: "Request for Examination Filed",
  },
];

export const analyticsObject = [
  {
    key: "total_application",
    label: "Total Applications",
    value: 0,
  },
  {
    key: "ordinary_complete_applications",
    label: "Ordinary Complete Applications",
    value: 0,
  },
  {
    key: "paris_convention",
    label: "Paris Convention",
    value: 0,
  },
  {
    key: "pct_national_phase",
    label: "PCT National Phase",
    value: 0,
  },
  {
    key: "divisional_applications",
    label: "Divisional Applications",
    value: 0,
  },
  {
    key: "ordinary_applications",
    label: "Ordinary Provisional",
    value: 0,
  },
];
