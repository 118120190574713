import { history } from "./../../history";
import { message } from "antd";

// const onRequest = (config) => {
//   return Promise.resolve(config);
// };

// const onRequestError = (error) => {
//   let status = error?.response?.status;
//   if (status === 401 || status === 403) {
//     localStorage.clear();
//     sessionStorage.clear();
//     history.push("/");
//     window.location.reload();
//   }
//   return Promise.reject(error);
// };

// const onResponse = (response) => {
//   return Promise.resolve(response);
// };

export const onResponseError = (error) => {
  const status = error?.response?.status || 0;
  const errorMessage =
    error?.response?.data?.message || "Something went wrong. Please try again.";

  switch (status) {
    case 400:
      message.error(
        "Oops! There was a problem with your request. Please check and try again.",
      );
      break;
    case 401:
      localStorage.clear();
      sessionStorage.clear();
      history.push("/");
      window.location.reload();
      message.error("You need to log in to continue.");
      break;
    case 403:
      message.error(
        "Sorry, you don’t have permission to access this resource.",
      );
      break;
    case 404:
      message.error("We couldn’t find what you’re looking for.");
      break;
    case 405:
      message.error("The action you're trying to perform is not allowed.");
      break;
    case 406:
      message.error(
        "The server couldn’t provide the requested content in the format you need.",
      );
      break;
    case 408:
      message.error("The server took too long to respond. Please try again.");
      break;
    case 409:
      message.error("There’s a conflict with your request. Please try again.");
      break;
    case 410:
      message.error("The resource you’re looking for is no longer available.");
      break;
    case 411:
      message.error(
        "The request is missing some important details. Please check and try again.",
      );
      break;
    case 413:
      message.error(
        "The information you provided is too large. Please reduce it and try again.",
      );
      break;
    case 414:
      message.error(
        "The link you’re using is too long. Please try again with a shorter one.",
      );
      break;
    case 415:
      message.error("The file format or data type is not supported.");
      break;
    case 417:
      message.error(
        "The server couldn’t process your request. Please try again.",
      );
      break;
    case 429:
      message.error(
        "You’re making requests too quickly. Please slow down and try again later.",
      );
      break;
    case 500:
      message.error("Something went wrong on our end. Please try again later.");
      break;
    case 501:
      message.error(
        "This feature is not available yet. Please try again later.",
      );
      break;
    case 502:
      message.error(
        "There was a problem connecting to the server. Please try again.",
      );
      break;
    case 503:
      message.error(
        "The service is temporarily unavailable. Please try again later.",
      );
      break;
    case 504:
      message.error("The server took too long to respond. Please try again.");
      break;
    case 505:
      message.error("The server doesn’t support the version of the request.");
      break;
    case 507:
      message.error(
        "There is not enough storage to process the request. Please try again later.",
      );
      break;
    case 508:
      message.error(
        "The server encountered an issue while processing your request. Please try again.",
      );
      break;
    case 510:
      message.error(
        "An additional feature is required to complete this request. Please contact support.",
      );
      break;
    default:
      message.error(errorMessage);
      console.error("Unhandled error:", error);
      break;
  }

  return Promise.reject(error);
};

// export function setupInterceptorsTo(axiosInstance) {
//   axiosInstance.interceptors.request.use(onRequest, onRequestError);
//   axiosInstance.interceptors.response.use(onResponse, onResponseError);
//   return axiosInstance;
// }
