import React from "react";
import MikeContainer from "../../../../Watch/CommonComponents/Layout/MikeContainer";
import HeaderSection from "./HeaderSection";
import ContentSection from "./ContentSection";

const UnPublishedPatentDashboard = (props) => {
  const headerTabItems = [
    { key: "provisional_to_complete", label: "Provisional To Complete" },
    { key: "due_date_for_filing", label: "Due Date for Filing" },
    { key: "pct_convention_date", label: "PCT/ Convention Date" },
    { key: "form_18", label: "Form 18" },
    { key: "form_1", label: "Form 1" },
    { key: "form_3", label: "Form 3" },
    { key: "form_26", label: "Form 26" },
  ];

  const commonProps = {};
  const propsForHeader = {
    ...commonProps,
    tabItems: headerTabItems,
  };

  const propsForContent = {
    ...commonProps,
  };

  return (
    <MikeContainer
      header={<HeaderSection {...propsForHeader} />}
      content={<ContentSection />}
    />
  );
};

export default UnPublishedPatentDashboard;
