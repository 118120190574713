const initialState = {
  data: [],
  status: null,
  report_columns: [],
  dashboardType: "",
  combinePdf: {
    loading: false,
  },
  meta_data: {
    count: null,
    pageNumebr: 1,
  },
  loading: false,
  singleData: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    default:
      return state;
  }
}
