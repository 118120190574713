import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import {
  Layout,
  Row,
  Col,
  Tabs,
  Card,
  Table,
  Button,
  Input,
  Cascader,
  message,
  Image,
} from "antd";

//-------- ACTIONS--------
import { getReport } from "../../../redux/actions/searchActions/ReportAction";

//--------IP-SUITE CONSTANTS--------
import { LOADING } from "../../../constants/commonConstants/generalConstants";
import { changeStateValue_L1 } from "../../../functions/commonFunctions/generalFunctions";
import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import {
  Report_defaultPrimarySelectedFilters,
  Report_defaultPrimarySorting,
} from "../../../constants/searchConstants/reportConstants";
import SendEmail from "../../commonComponents/Modals/sendMail";
import SendEmailNew from "../../commonComponents/Modals/SendEmailNew";
import { logOut } from "../../../redux/actions/commonActions/actions";

const { Content } = Layout;
const { TabPane } = Tabs;
const { Search } = Input;

class ReportDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab:
        this.props.userSubscriptions &&
        this.props.userSubscriptions.search.includes("MARK") &&
        this.props.userSubscriptions.search.includes("PROPRIETOR")
          ? "mark_report"
          : this.props.userSubscriptions &&
              this.props.userSubscriptions.search.includes("MARK")
            ? "mark_report"
            : this.props.userSubscriptions &&
                this.props.userSubscriptions.search.includes("PROPRIETOR")
              ? "proprietor_report"
              : "mark_report",
      // activeTab: this.props.userSubscriptions && this.props.userSubscriptions.search.includes('PROPRIETOR') ? 'proprietor_report' : 'mark_report',
      primarySelectedFilters: {
        mark: this.props.markReportFilesAppliedFilters,
        proprietor: this.props.proprietorReportFilesAppliedFilters,
        ipIndia: this.props.ipIndiaReportFilesAppliedFilters,
      },
      primarySorting: {
        mark: this.props.markReportFilesAppliedSorting,
        proprietor: this.props.proprietorReportFilesAppliedSorting,
        ipIndia: this.props.ipIndiaReportFilesAppliedSorting,
      },
      primaryPageNumber: {
        mark: this.props.markReportFilesPageNumber,
        proprietor: this.props.proprietorReportFilesPageNumber,
        ipIndia: this.props.ipIndiaReportFilesPageNumber,
      },
      modalsVisibility: {
        sendEmail: false,
        attachment: null,
        mailContent: "",
        report_tag: {},
      },
    };
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      this.props.getReport({
        report_source: "MARK",
        check: false,
        files: true,
        filterFlag: false,
        filters: Report_defaultPrimarySelectedFilters,
        sortFlag: false,
        sorting: Report_defaultPrimarySorting,
      });

      this.props.getReport({
        report_source: "PROPRIETOR",
        check: false,
        files: true,
        filterFlag: false,
        filters: Report_defaultPrimarySelectedFilters,
        sortFlag: false,
        sorting: Report_defaultPrimarySorting,
      });

      this.props.getReport({
        report_source: "IPINDIA",
        check: false,
        files: true,
        filterFlag: false,
        filters: Report_defaultPrimarySelectedFilters,
        sortFlag: false,
        sorting: Report_defaultPrimarySorting,
      });

      this.props.getReport({
        report_source: "IMAGE_SEARCH",
        check: false,
        files: true,
        filterFlag: false,
      });
    } else {
      this.props.logOut();
    }
  }

  handleApplyPrimaryFilters(searchInput) {
    const { activeTab } = this.state;
    const {
      markReportFilesAppliedSorting,
      proprietorReportFilesAppliedSorting,
      ipIndiaReportFilesAppliedSorting,
    } = this.props;
    const primarySorting =
      activeTab === "mark_report"
        ? markReportFilesAppliedSorting
        : activeTab === "proprietor_report"
          ? proprietorReportFilesAppliedSorting
          : activeTab === "ipIndia_report"
            ? ipIndiaReportFilesAppliedSorting
            : "";
    const primarySelectedFilters =
      activeTab === "mark_report"
        ? this.state.primarySelectedFilters.mark
        : activeTab === "proprietor_report"
          ? this.state.primarySelectedFilters.proprietor
          : activeTab === "ipIndia_report"
            ? this.state.primarySelectedFilters.ipIndia
            : "";

    if (searchInput !== primarySelectedFilters?.containsTerm) {
      this.props.getReport({
        check: false,
        files: true,
        report_source:
          activeTab === "mark_report"
            ? "MARK"
            : activeTab === "proprietor_report"
              ? "PROPRIETOR"
              : activeTab === "ipIndia_report"
                ? "IPINDIA"
                : "",
        filterFlag: true,
        filters: {
          ...primarySelectedFilters,
          containsTerm: searchInput,
        },
        sortFlag: primarySorting?.sortFlag,
        sorting: primarySorting,
      });
      this.setState({
        primarySelectedFilters: {
          ...this.state.primarySelectedFilters,
          [activeTab === "mark_report"
            ? "mark"
            : activeTab === "mark_report"
              ? "proprietor"
              : activeTab === "mark_report"
                ? "ipIndia"
                : ""]: {
            ...this.state.primarySelectedFilters[
              activeTab === "mark_report"
                ? "mark"
                : activeTab === "proprietor_report"
                  ? "proprietor"
                  : activeTab === "ipIndia_report"
                    ? "ipIndia"
                    : ""
            ],
            containsTerm: searchInput,
            filterFlag: true,
          },
        },
      });
    } else {
      message.error("please enter search value!");
    }
  }

  handleResetPrimaryFilters() {
    const { activeTab } = this.state;
    const {
      markReportFilesAppliedFilters,
      proprietorReportFilesAppliedFilters,
      ipIndiaReportFilesAppliedFilters,
      markReportFilesAppliedSorting,
      proprietorReportFilesAppliedSorting,
      ipIndiaReportFilesAppliedSorting,
    } = this.props;
    const { containsTerm } =
      activeTab === "mark_report"
        ? markReportFilesAppliedFilters || {}
        : activeTab === "proprietor_report"
          ? proprietorReportFilesAppliedFilters || {}
          : activeTab === "ipIndia_report"
            ? ipIndiaReportFilesAppliedFilters || {}
            : "";
    const primarySorting =
      activeTab === "mark_report"
        ? markReportFilesAppliedSorting
        : activeTab === "proprietor_report"
          ? proprietorReportFilesAppliedSorting
          : activeTab === "ipIndia_report"
            ? ipIndiaReportFilesAppliedSorting
            : "";

    if (containsTerm && containsTerm?.length > 0) {
      this.props.getReport({
        check: false,
        files: true,
        report_source:
          activeTab === "mark_report"
            ? "MARK"
            : activeTab === "proprietor_report"
              ? "PROPRIETOR"
              : activeTab === "ipIndia_report"
                ? "IPINDIA"
                : "",
        filterFlag: false,
        filters: Report_defaultPrimarySelectedFilters,
        sortFlag: primarySorting.sortFlag,
        sorting: primarySorting,
      });
      this.setState({
        primarySelectedFilters: {
          ...this.state.primarySelectedFilters,
          [activeTab === "mark_report"
            ? "mark"
            : activeTab === "proprietor_report"
              ? "proprietor"
              : activeTab === "ipIndia_report"
                ? "ipIndia"
                : ""]: Report_defaultPrimarySelectedFilters,
        },
      });
    } else {
      message.error("No Filter Applied");
    }
  }

  handleApplyPrimarySorting(sortValue) {
    const { activeTab } = this.state;
    const {
      markReportFilesAppliedFilters,
      proprietorReportFilesAppliedFilters,
      ipIndiaReportFilesAppliedFilters,
      markReportFilesPageNumber,
      proprietorReportFilesPageNumber,
      ipIndiaReportFilesPageNumber,
    } = this.props;
    const primarySelectedFilters =
      activeTab === "mark_report"
        ? markReportFilesAppliedFilters
        : activeTab === "proprietor_report"
          ? proprietorReportFilesAppliedFilters
          : activeTab === "ipIndia_report"
            ? ipIndiaReportFilesAppliedFilters
            : "";
    const primaryPageNumber =
      activeTab === "mark_report"
        ? markReportFilesPageNumber
        : activeTab === "proprietor_report"
          ? proprietorReportFilesPageNumber
          : activeTab === "ipIndia_report"
            ? ipIndiaReportFilesPageNumber
            : "";
    const primarySorting =
      activeTab === "mark_report"
        ? this.state.primarySorting.proprietor
        : activeTab === "proprietor_report"
          ? this.state.primarySorting.mark
          : activeTab === "ipIndia_report"
            ? this.state.primarySorting.ipIndia
            : "";

    if (sortValue?.length !== primarySorting?.sortValue) {
      if (sortValue[0] === "CLEAR") {
        this.props.getReport({
          check: false,
          files: true,
          report_source:
            activeTab === "mark_report"
              ? "MARK"
              : activeTab === "proprietor_report"
                ? "PROPRIETOR"
                : activeTab === "ipIndia_report"
                  ? "IPINDIA"
                  : "",
          filterFlag: primarySelectedFilters.filterFlag,
          filters: primarySelectedFilters,
          sortFlag: Report_defaultPrimarySorting.sortFlag,
          sorting: Report_defaultPrimarySorting,
          pageNumber: primaryPageNumber,
        });
        this.setState({
          primarySorting: {
            ...this.state.primarySorting,
            [activeTab === "mark_report"
              ? "mark"
              : activeTab === "proprietor_report"
                ? "proprietor"
                : activeTab === "ipIndia_report"
                  ? "ipIndia"
                  : ""]: Report_defaultPrimarySorting,
          },
        });
      } else {
        this.props.getReport({
          check: false,
          files: true,
          report_source:
            activeTab === "mark_report"
              ? "MARK"
              : activeTab === "proprietor_report"
                ? "PROPRIETOR"
                : activeTab === "ipIndia_report"
                  ? "IPINDIA"
                  : "",
          filterFlag: primarySelectedFilters.filterFlag,
          filters: primarySelectedFilters,
          sortFlag: true,
          sorting: {
            ...primarySorting,
            sortType: sortValue[0],
            sortOn: sortValue[1],
          },
          pageNumber: primaryPageNumber,
        });
        this.setState({
          primarySorting: {
            ...this.state.primarySorting,
            [activeTab === "mark_report"
              ? "mark"
              : activeTab === "proprietor_report"
                ? "proprietor"
                : activeTab === "ipIndia_report"
                  ? "ipIndia"
                  : ""]: {
              ...this.state.primarySorting[
                activeTab === "mark_report"
                  ? "mark"
                  : activeTab === "proprietor_report"
                    ? "proprietor"
                    : activeTab === "ipIndia_report"
                      ? "ipIndia"
                      : ""
              ],
              sortType: sortValue[0],
              sortOn: sortValue[1],
              sortFlag: true,
              sortValue: sortValue,
            },
          },
        });
      }
    } else {
      message.error("please select value!");
    }
  }

  handleNextpage = (pageNumber) => {
    const { activeTab } = this.state;
    const {
      markReportFilesAppliedFilters,
      proprietorReportFilesAppliedFilters,
      ipIndiaReportFilesAppliedFilters,
      markReportFilesAppliedSorting,
      proprietorReportFilesAppliedSorting,
      ipIndiaReportFilesAppliedSorting,
    } = this.props;
    const primarySelectedFilters =
      activeTab === "mark_report"
        ? markReportFilesAppliedFilters
        : activeTab === "proprietor_report"
          ? proprietorReportFilesAppliedFilters
          : activeTab === "ipIndia_report"
            ? ipIndiaReportFilesAppliedFilters
            : "";
    const primarySorting =
      activeTab === "mark_report"
        ? markReportFilesAppliedSorting
        : activeTab === "proprietor_report"
          ? proprietorReportFilesAppliedSorting
          : activeTab === "ipIndia_report"
            ? ipIndiaReportFilesAppliedSorting
            : "";

    this.props.getReport({
      check: false,
      files: true,
      report_source:
        activeTab === "mark_report"
          ? "MARK"
          : activeTab === "proprietor_report"
            ? "PROPRIETOR"
            : activeTab === "ipIndia_report"
              ? "IPINDIA"
              : "",
      filterFlag: primarySelectedFilters.filterFlag,
      filters: primarySelectedFilters,
      sortFlag: primarySorting.sortFlag,
      sorting: primarySorting,
      pageNumber: pageNumber,
    });
    this.setState({
      primaryPageNumber: {
        ...this.state.primaryPageNumber,
        [activeTab === "mark_report"
          ? "mark"
          : activeTab === "proprietor_report"
            ? "proprietor"
            : activeTab === "ipIndia_report"
              ? "ipIndia"
              : ""]: pageNumber,
      },
    });
  };

  handleEmail(modalsVisibility, modal, value, url, data, file) {
    const { activeTab } = this.state;
    let mailContent;
    let subscription;
    let title = checkValue(data.title) ? data.title : "";
    let createdDate = checkValue(file.created) ? file.created : "";

    if (activeTab === "mark_report") {
      mailContent =
        "<p>Attached is the Public Search Report created for your mark <strong>" +
        title +
        "</strong> on <strong>" +
        createdDate +
        "</strong>.</p><p>Kindly advise whether you wish to proceed with the filing of this mark or want us to check for the availability of other marks.</p>";
      subscription = ["MARK"];
    } else if (activeTab === "proprietor_report") {
      mailContent =
        "<p>Attached is the Public Search Report created for your mark <strong>" +
        title +
        "</strong> on <strong>" +
        createdDate +
        "</strong>.</p><p>Kindly advise whether you wish to proceed with the filing of this mark or want us to check for the availability of other marks.</p>";
      subscription = ["PROPRIETOR"];
    } else if (activeTab === "ipIndia_report") {
      mailContent =
        "<p>Attached is the Public Search Report created for your mark <strong>" +
        title +
        "</strong> on <strong>" +
        createdDate +
        "</strong>.</p><p>Kindly advise whether you wish to proceed with the filing of this mark or want us to check for the availability of other marks.</p>";
      subscription = ["MARK"];
    } else {
      mailContent = "<p>No report files available here</p>";
      subscription = ["MARK"];
    }

    this.setState({
      [modalsVisibility]: {
        ...this.state[modalsVisibility],
        [modal]: value,
        attachment: url,
        mailContent: mailContent,
        product: "SEARCH",
        subscription: subscription,
        report_tag: {
          mark_name: title,
          created_date: createdDate,
        },
      },
    });
  }

  render() {
    const {
      userSubscriptions,
      userDetails,
      userTeam,
      markReportFiles,
      markReportFilesStatus,
      proprietorReportFiles,
      proprietorReportFilesStatus,
      imageSearchReportFiles,
      imageSearchReportFilesStatus,
      ipIndiaReportFiles,
      ipIndiaReportFilesStatus,
    } = this.props;

    const { activeTab, primarySorting, primaryPageNumber, modalsVisibility } =
      this.state;

    const columnsReport =
      activeTab !== "image_search_report"
        ? [
            {
              title: "Mark Name",
              dataIndex: "title",
              key: "title",
              width: "20%",
            },
            {
              title: "Type",
              dataIndex: "search_subscription",
              key: "search_subscription",
              width: "20%",
              render: (files, record) => {
                return (
                  <div>
                    {record?.search_subscription === "GOODS_SERVICE" &&
                      "Goods & Service"}
                    {record?.search_subscription === "TRADEMARK" && "Mark"}
                  </div>
                );
              },
            },
            {
              title: "Modified Date",
              dataIndex: "modified",
              key: "modified",
              width: "15%",
            },
            {
              title: "Files",
              dataIndex: "files",
              key: "files",
              width: "65%",
              align: "center",
              render: (files, record) => {
                return files.length > 0 ? (
                  <table className="report-files-table">
                    <thead>
                      <tr>
                        <th style={{ width: "30%" }}>Report Name</th>
                        <th style={{ width: "25%" }}>Created Date</th>
                        <th style={{ width: "15%" }}>Report Type</th>
                        <th style={{ width: "15%" }}>Download</th>
                        <th style={{ width: "15%" }}>Send Mail</th>
                      </tr>
                    </thead>
                    <tbody>
                      {files.map((filedata, index) => (
                        <tr key={`${filedata.created}${index}`}>
                          <td>
                            {checkValue(filedata.name) ? filedata.name : "N.A."}
                          </td>
                          <td>
                            {checkValue(filedata.created)
                              ? filedata.created
                              : "N.A."}
                          </td>
                          <td>
                            {checkValue(filedata.type)
                              ? filedata.type.toUpperCase().replace("_", " ")
                              : "N.A."}
                          </td>
                          <td>
                            <Button
                              ghost
                              type="primary"
                              className={
                                checkValue(filedata?.url) ? "" : "display_none"
                              }
                              style={{
                                width: "90px",
                                height: "30px",
                                margin: "0px 10px",
                              }}
                              onClick={() =>
                                window.open(filedata?.url, "_blank")
                              }
                            >
                              {checkValue(filedata.type)
                                ? filedata.type.toUpperCase() === "PDF"
                                  ? "PDF"
                                  : filedata.type.toUpperCase() === "EXCEL" ||
                                      filedata.type.toUpperCase() ===
                                        "EXCEL_BIFURCATED"
                                    ? "EXCEL"
                                    : ""
                                : ""}
                            </Button>
                          </td>
                          <td>
                            <Button
                              ghost
                              type="primary"
                              className={
                                checkValue(filedata?.url) ? "" : "display_none"
                              }
                              onClick={() =>
                                this.handleEmail(
                                  "modalsVisibility",
                                  "sendEmail",
                                  true,
                                  [filedata?.url],
                                  record,
                                  filedata,
                                )
                              }
                            >
                              Send
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  "No report files available here"
                );
              },
            },
          ]
        : [
            {
              title: "Image",
              dataIndex: "title",
              key: "title",
              width: "20%",
              render: (files, record) => {
                return (
                  <Image
                    width={100}
                    src={checkValue(record?.image) ? record?.image : ""}
                  />
                );
              },
            },
            {
              title: "Modified Date",
              dataIndex: "modified",
              key: "modified",
              width: "15%",
            },
            {
              title: "Files",
              dataIndex: "files",
              key: "files",
              width: "65%",
              align: "center",
              render: (files, record) => {
                return files.length > 0 ? (
                  <table className="report-files-table">
                    <thead>
                      <tr>
                        <th style={{ width: "30%" }}>Report Name</th>
                        <th style={{ width: "25%" }}>Created Date</th>
                        <th style={{ width: "15%" }}>Report Type</th>
                        <th style={{ width: "15%" }}>Download</th>
                        <th style={{ width: "15%" }}>Send Mail</th>
                      </tr>
                    </thead>
                    <tbody>
                      {files.map((filedata, index) => (
                        <tr key={`${filedata.created}${index}`}>
                          <td>
                            {checkValue(filedata.name) ? filedata.name : "N.A."}
                          </td>
                          <td>
                            {checkValue(filedata.created)
                              ? filedata.created
                              : "N.A."}
                          </td>
                          <td>
                            {checkValue(filedata.type)
                              ? filedata.type.toUpperCase().replace("_", " ")
                              : "N.A."}
                          </td>
                          <td>
                            <Button
                              ghost
                              type="primary"
                              className={
                                checkValue(filedata?.url) ? "" : "display_none"
                              }
                              style={{
                                width: "90px",
                                height: "30px",
                                margin: "0px 10px",
                              }}
                              onClick={() =>
                                window.open(filedata?.url, "_blank")
                              }
                            >
                              {checkValue(filedata.type)
                                ? filedata.type.toUpperCase() === "PDF"
                                  ? "PDF"
                                  : filedata.type.toUpperCase() === "EXCEL" ||
                                      filedata.type.toUpperCase() ===
                                        "EXCEL_BIFURCATED"
                                    ? "EXCEL"
                                    : ""
                                : ""}
                            </Button>
                          </td>
                          <td>
                            <Button
                              ghost
                              type="primary"
                              className={
                                checkValue(filedata?.url) ? "" : "display_none"
                              }
                              onClick={() =>
                                this.handleEmail(
                                  "modalsVisibility",
                                  "sendEmail",
                                  true,
                                  [filedata?.url],
                                  record,
                                  filedata,
                                )
                              }
                            >
                              Send
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  "No report files available here"
                );
              },
            },
          ];

    return (
      <div>
        <Layout className="Report">
          <Content style={{ margin: "0px" }}>
            <Row
              className="content-container vertical_scroll"
              style={{
                height: `${
                  this.props.searchAlert.length > 0
                    ? "calc(100vh - 40px)"
                    : "calc(100vh - 0px)"
                }`,
              }}
            >
              <Col span={24}>
                <Tabs
                  style={{ textAlign: "left" }}
                  type="card"
                  activeKey={activeTab}
                  onChange={(activeKey) =>
                    this.setState({ activeTab: activeKey })
                  }
                >
                  {userSubscriptions &&
                    userSubscriptions.search.includes("MARK") && (
                      <TabPane tab="TRADEMARK REPORTS" key="mark_report">
                        <Row>
                          <Col span={24} className="alignL">
                            <Search
                              placeholder="Search Mark name"
                              onSearch={(searchInput) =>
                                this.handleApplyPrimaryFilters(searchInput)
                              }
                              enterButton={
                                <Button
                                  style={{
                                    backgroundColor: "#4285f4",
                                    color: "#ffffff",
                                    fontWeight: "600",
                                  }}
                                >
                                  Apply
                                </Button>
                              }
                              style={{ width: "20vw", minWidth: "150px" }}
                            />
                            <Button
                              type="link"
                              onClick={() => this.handleResetPrimaryFilters()}
                            >
                              Reset
                            </Button>
                            <Cascader
                              options={[
                                {
                                  value: "ASC",
                                  label: "Ascending",
                                  children: [
                                    {
                                      value: "TITLE",
                                      label: "Title",
                                    },
                                    {
                                      value: "MODIFIED",
                                      label: "Modified Date",
                                    },
                                  ],
                                },
                                {
                                  value: "DESC",
                                  label: "Descending",
                                  children: [
                                    {
                                      value: "TITLE",
                                      label: "Title",
                                    },
                                    {
                                      value: "MODIFIED",
                                      label: "Modified Date",
                                    },
                                  ],
                                },
                                {
                                  value: "CLEAR",
                                  label: "Clear All",
                                  children: [],
                                },
                              ]}
                              allowClear={false}
                              placeholder="Sorting"
                              expandTrigger="hover"
                              value={primarySorting?.mark?.sortValue}
                              onChange={(value) =>
                                this.handleApplyPrimarySorting(value)
                              }
                            />
                          </Col>
                          <Col span={24} className="m-top-20">
                            <Card title="TRADEMARK REPORT">
                              <Table
                                rowKey={(record) => record.id}
                                columns={columnsReport}
                                bordered
                                dataSource={
                                  markReportFiles && markReportFiles.reports
                                }
                                pagination={{
                                  pageSize: 100,
                                  showQuickJumper: true,
                                  showSizeChanger: false,
                                  defaultCurrent: primaryPageNumber.mark,
                                  total:
                                    markReportFiles && markReportFiles.count,
                                  onChange: this.handleNextpage,
                                }}
                                scroll={{ y: "calc(70vh - 150px)" }}
                                loading={
                                  markReportFilesStatus === LOADING
                                    ? true
                                    : false
                                }
                              />
                            </Card>
                          </Col>
                        </Row>
                      </TabPane>
                    )}
                  {/* {userSubscriptions &&
                    userSubscriptions.search.includes("MARK") && (
                      <TabPane tab="IP INDIA REPORTS" key="ipIndia_report">
                        <Row>
                          <Col span={24} className="alignL">
                            <Search
                              placeholder="Search Mark name"
                              onSearch={(searchInput) =>
                                this.handleApplyPrimaryFilters(searchInput)
                              }
                              enterButton={
                                <Button
                                  style={{
                                    backgroundColor: "#4285f4",
                                    color: "#ffffff",
                                    fontWeight: "600",
                                  }}
                                >
                                  Apply
                                </Button>
                              }
                              style={{ width: "20vw", minWidth: "150px" }}
                            />
                            <Button
                              type="link"
                              onClick={() => this.handleResetPrimaryFilters()}
                            >
                              Reset
                            </Button>
                            <Cascader
                              options={[
                                {
                                  value: "ASC",
                                  label: "Ascending",
                                  children: [
                                    {
                                      value: "TITLE",
                                      label: "Title",
                                    },
                                    {
                                      value: "MODIFIED",
                                      label: "Modified Date",
                                    },
                                  ],
                                },
                                {
                                  value: "DESC",
                                  label: "Descending",
                                  children: [
                                    {
                                      value: "TITLE",
                                      label: "Title",
                                    },
                                    {
                                      value: "MODIFIED",
                                      label: "Modified Date",
                                    },
                                  ],
                                },
                                {
                                  value: "CLEAR",
                                  label: "Clear All",
                                  children: [],
                                },
                              ]}
                              allowClear={false}
                              placeholder="Sorting"
                              expandTrigger="hover"
                              value={primarySorting?.ipIndia?.sortValue}
                              onChange={(value) =>
                                this.handleApplyPrimarySorting(value)
                              }
                            />
                          </Col>
                          <Col span={24} className="m-top-20">
                            <Card title="IP INDIA REPORT">
                              <Table
                                rowKey={(record) => record.id}
                                columns={columnsReport}
                                bordered
                                dataSource={
                                  ipIndiaReportFiles &&
                                  ipIndiaReportFiles.reports
                                }
                                pagination={{
                                  pageSize: 100,
                                  showQuickJumper: true,
                                  showSizeChanger: false,
                                  defaultCurrent: primaryPageNumber.ipIndia,
                                  total:
                                    ipIndiaReportFiles &&
                                    ipIndiaReportFiles.count,
                                  onChange: this.handleNextpage,
                                }}
                                scroll={{ y: "calc(70vh - 150px)" }}
                                loading={
                                  ipIndiaReportFilesStatus === LOADING
                                    ? true
                                    : false
                                }
                              />
                            </Card>
                          </Col>
                        </Row>
                      </TabPane>
                    )} */}
                  {userSubscriptions &&
                    userSubscriptions.search.includes("PROPRIETOR") && (
                      <TabPane tab="PROPRIETOR REPORTS" key="proprietor_report">
                        <Row>
                          <Col span={24} className="alignL">
                            <Search
                              placeholder="Search Mark name"
                              onSearch={(searchInput) =>
                                this.handleApplyPrimaryFilters(searchInput)
                              }
                              enterButton={
                                <Button
                                  style={{
                                    backgroundColor: "#4285f4",
                                    color: "#ffffff",
                                    fontWeight: "600",
                                  }}
                                >
                                  Apply
                                </Button>
                              }
                              style={{ width: "20vw", minWidth: "150px" }}
                            />
                            <Button
                              type="link"
                              onClick={() => this.handleResetPrimaryFilters()}
                            >
                              Reset
                            </Button>
                            <Cascader
                              options={[
                                {
                                  value: "ASC",
                                  label: "Ascending",
                                  children: [
                                    {
                                      value: "TITLE",
                                      label: "Title",
                                    },
                                    {
                                      value: "MODIFIED",
                                      label: "Modified Date",
                                    },
                                  ],
                                },
                                {
                                  value: "DESC",
                                  label: "Descending",
                                  children: [
                                    {
                                      value: "TITLE",
                                      label: "Title",
                                    },
                                    {
                                      value: "MODIFIED",
                                      label: "Modified Date",
                                    },
                                  ],
                                },
                                {
                                  value: "CLEAR",
                                  label: "Clear All",
                                  children: [],
                                },
                              ]}
                              allowClear={false}
                              placeholder="Sorting"
                              expandTrigger="hover"
                              value={primarySorting?.proprietor?.sortValue}
                              onChange={(value) =>
                                this.handleApplyPrimarySorting(value)
                              }
                            />
                          </Col>
                          <Col span={24} className="m-top-20">
                            <Card title="PROPRIETOR REPORTS">
                              <Table
                                bordered
                                rowKey={(record) => record.id}
                                columns={columnsReport}
                                dataSource={
                                  proprietorReportFiles &&
                                  proprietorReportFiles.reports
                                }
                                pagination={{
                                  pageSize: 100,
                                  showQuickJumper: true,
                                  showSizeChanger: false,
                                  defaultCurrent: primaryPageNumber.proprietor,
                                  total:
                                    proprietorReportFiles &&
                                    proprietorReportFiles.count,
                                  onChange: this.handleNextpage,
                                }}
                                scroll={{ y: "calc(70vh - 150px)" }}
                                loading={
                                  proprietorReportFilesStatus === LOADING
                                }
                              />
                            </Card>
                          </Col>
                        </Row>
                      </TabPane>
                    )}
                  {userSubscriptions &&
                    userSubscriptions.search.includes("IMAGE_SEARCH") && (
                      <TabPane
                        tab="IMAGE SEARCH REPORTS"
                        key="image_search_report"
                      >
                        <Row>
                          <Col span={24} className="m-top-20">
                            <Table
                              bordered
                              rowKey={(record) => record.id}
                              columns={columnsReport}
                              dataSource={
                                imageSearchReportFiles &&
                                imageSearchReportFiles.reports
                              }
                              pagination={{
                                pageSize: 100,
                                showQuickJumper: true,
                                showSizeChanger: false,
                                defaultCurrent: primaryPageNumber.imageSearch,
                                total:
                                  imageSearchReportFiles &&
                                  imageSearchReportFiles.count,
                                onChange: this.handleNextpage,
                              }}
                              scroll={{ y: "calc(70vh - 48px)" }}
                              loading={imageSearchReportFilesStatus === LOADING}
                            />
                          </Col>
                        </Row>
                      </TabPane>
                    )}
                </Tabs>
              </Col>
            </Row>
          </Content>
        </Layout>

        <SendEmailNew
          visible={modalsVisibility.sendEmail}
          onCloseModal={() =>
            this.handleEmail("modalsVisibility", "sendEmail", false, [], {}, {})
          }
          sendFrom={userDetails && userDetails.communication_email}
          sendCC={userTeam && userTeam.search}
          mailContent={modalsVisibility.mailContent}
          attachment={modalsVisibility.attachment}
          product={modalsVisibility.product}
          subscription={modalsVisibility.subscription}
          report_tag={modalsVisibility.report_tag}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getReport,
  logOut,
};

function mapStateToProps(state) {
  const {
    markReportFiles,
    markReportFilesStatus,
    markReportFilesAppliedFilters,
    markReportFilesAppliedSorting,
    markReportFilesPageNumber,

    proprietorReportFiles,
    proprietorReportFilesStatus,
    proprietorReportFilesAppliedFilters,
    proprietorReportFilesAppliedSorting,
    proprietorReportFilesPageNumber,

    imageSearchReportFiles,
    imageSearchReportFilesStatus,
    imageSearchReportFilesAppliedFilters,
    imageSearchReportFilesAppliedSorting,
    imageSearchReportFilesPageNumber,

    ipIndiaReportFiles,
    ipIndiaReportFilesStatus,
    ipIndiaReportFilesAppliedFilters,
    ipIndiaReportFilesAppliedSorting,
    ipIndiaReportFilesPageNumber,
  } = state.searchState.report;
  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;
  return deepFreeze({
    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,

    markReportFiles,
    markReportFilesStatus,
    markReportFilesAppliedFilters,
    markReportFilesAppliedSorting,
    markReportFilesPageNumber,

    proprietorReportFiles,
    proprietorReportFilesStatus,
    proprietorReportFilesAppliedFilters,
    proprietorReportFilesAppliedSorting,
    proprietorReportFilesPageNumber,

    imageSearchReportFiles,
    imageSearchReportFilesStatus,
    imageSearchReportFilesAppliedFilters,
    imageSearchReportFilesAppliedSorting,
    imageSearchReportFilesPageNumber,

    ipIndiaReportFiles,
    ipIndiaReportFilesStatus,
    ipIndiaReportFilesAppliedFilters,
    ipIndiaReportFilesAppliedSorting,
    ipIndiaReportFilesPageNumber,
    searchAlert: state.alertMessages.search,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportDownload);
