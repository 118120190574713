import { analyticsObject } from "../../../../components/managerComponents/patent/UnPublishedPatent/Manage/common/constant";
import {
  ERROR,
  LOADING,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";
import {
  ADD_UNPUBLISHED_PATENT,
  DELETE_UNPUBLISHED_PATENT,
  GET_MANAGE_UNPUBLISHED_PATENT_LIST,
  GET_SINGLE_UNPUBLISHED_PATENT,
  GET_UNPUBLISHED_PATENT_STATISTICS,
  UPDATE_UNPUBLISHED_PATENT,
  UPDATE_UNPUBLISHED_PATENT_DEADLINES,
} from "../../../actions/managerActions/unPublishedPatentDashboard/types";

const initialState = {
  data: [],
  report_columns: [],
  metadata: {
    count: 0,
    pageNumber: 1,
  },
  loading: false,
  singleData: {
    data: {},
    loading: false,
    isDataLoaded: false,
  },
  stats: {
    data: analyticsObject,
    loading: false,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_MANAGE_UNPUBLISHED_PATENT_LIST: {
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            data: action.payload.data,
            isDataLoaded: true,
            loading: false,
            metadata: {
              count: action.payload.metadata.count,
              pageNumber: action.pageNumber,
            },
            report_columns: action?.payload?.metadata?.report_columns ?? [],
          };

        case LOADING:
          return {
            ...state,
            loading: true,
            isDataLoaded: false,
          };

        case ERROR:
          return {
            ...state,
            data: [],
            isDataLoaded: false,
            loading: false,
            metadata: {
              count: 0,
              pageNumber: 1,
            },
          };

        default:
          return state;
      }
    }

    case ADD_UNPUBLISHED_PATENT: {
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            loading: true,
          };
        case ERROR:
          return {
            ...state,
            loading: false,
          };
        case SUCCESS:
          return {
            ...state,
            loading: false,
          };
        default:
          return state;
      }
    }

    case DELETE_UNPUBLISHED_PATENT: {
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            loading: true,
            isDataLoaded: false,
          };
        default:
          return state;
      }
    }

    case UPDATE_UNPUBLISHED_PATENT: {
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            loading: true,
          };
        case ERROR:
          return {
            ...state,
            loading: false,
          };
        case SUCCESS:
          return {
            ...state,
            loading: false,
          };
        default:
          return state;
      }
    }

    case GET_SINGLE_UNPUBLISHED_PATENT: {
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            singleData: {
              ...state.singleData,
              data: action.payload.data,
              loading: false,
              isDataLoaded: true,
            },
          };

        case LOADING:
          return {
            ...state,
            singleData: {
              ...state.singleData,
              loading: true,
              isDataLoaded: false,
            },
          };

        case ERROR:
          return {
            ...state,
            singleData: {
              ...state.singleData,
              data: {},
              loading: false,
              isDataLoaded: false,
            },
          };

        default:
          return state;
      }
    }

    case GET_UNPUBLISHED_PATENT_STATISTICS: {
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            stats: {
              ...state.stats,
              loading: true,
            },
          };
        case ERROR:
          return {
            ...state,
            stats: {
              ...state.stats,
              loading: false,
            },
          };
        case SUCCESS:
          return {
            ...state,
            stats: {
              ...state.stats,
              data: action.payload.data,
              loading: false,
            },
          };
        default:
          return state;
      }
    }

    case UPDATE_UNPUBLISHED_PATENT_DEADLINES: {
      switch (action.status) {
        case LOADING:
          return {
            ...state,
            loading: true,
          };
        case ERROR:
          return {
            ...state,
            loading: false,
          };
        case SUCCESS:
          return {
            ...state,
            loading: false,
          };
        default:
          return state;
      }
    }

    default:
      return state;
  }
}
