import React from "react";
import { useDispatch } from "react-redux";
import CommonSingleDateFilter from "../../../../commonComponents/filters/CommonSingleDateFilter";
import { changeUnPublishedPatentDateFilterValues } from "../../../../../../redux/actions/managerActions/unPublishedPatentDashboard/managePatent";

const SingleDateFilterContainer = (props) => {
  const dispatch = useDispatch();

  const handleDateChange = (filter_key, value) => {
    dispatch(changeUnPublishedPatentDateFilterValues(filter_key, value));
  };

  const handleProposedChange = (filter_key, value) => {
    // code for proposed feature here...;
  };

  return (
    <CommonSingleDateFilter
      {...props}
      handleDateChange={handleDateChange}
      handleProposedChange={handleProposedChange}
    />
  );
};

export default SingleDateFilterContainer;
