import React from "react";
import { Typography } from "antd";

const { Text } = Typography;

const HighlightPrefix = ({ text, highlightLength = 3, suffix = false }) => {
  // Helper function to handle the highlighting logic
  const highlightWord = (word) => {
    if (word.length <= highlightLength) {
      return (
        <span>
          <Text style={{ fontSize: "18px", letterSpacing: "1.2px" }} mark>
            {word}
          </Text>{" "}
          {/* Highlight entire word if it's shorter than highlightLength */}
        </span>
      );
    }

    if (suffix) {
      return (
        <span>
          <Text style={{ fontSize: "18px", letterSpacing: "1.2px" }}>
            {word.slice(0, word.length - highlightLength)}
          </Text>
          <Text style={{ fontSize: "18px", letterSpacing: "1.2px" }} mark>
            {word.slice(-highlightLength)}
          </Text>
        </span>
      );
    }
    return (
      <span>
        <Text style={{ fontSize: "18px", letterSpacing: "1.2px" }} mark>
          {word.slice(0, highlightLength)}
        </Text>
        <Text style={{ fontSize: "18px", letterSpacing: "1.2px" }}>
          {word.slice(highlightLength)}
        </Text>
      </span>
    );
  };

  // Split the input text into words
  const words = text.split(" ");

  return (
    <div>
      {words.map((word, index) => (
        <span key={index}>
          {highlightWord(word)}
          {index < words.length - 1 && " "}
        </span>
      ))}
    </div>
  );
};

export default HighlightPrefix;
